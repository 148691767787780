<template>
    <div>
        <div class="pb-3 w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
            <h4 class="text__page-header mt-2 w-100 d-flex justify-content-start">Patient Management</h4>
            <!-- <button
                v-if="isDev || isLocalHost"
                v-can-access.role="'admin'"
                @click="showInviteUserWithPartnerModal = !showInviteUserWithPartnerModal"
                class="btn btn-primary mt-2">
                Invite user with partner code
            </button> -->
        </div>
        <div v-if="loggedUser.isDoctor || loggedUser.isAdmin || loggedUser.isPartnerPlus">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <users-table
                        :users="allPatients"
                        @userSelected="viewUserInfo"
                        @pageChange="onPageChange"
                        @search="onSearchTerm"
                        :page="page"
                        :pageSize="pageSize"
                        :total="total"
                        :queryString="queryString"
                        @connectToPartner="
                            (user) => {
                                userInModal = user;
                                showConnectToPartnerModal = !showConnectToPartnerModal;
                            }
                        "
                        :isLoading="isLoading" />
                </div>
            </div>
        </div>

        <slot v-if="showConnectToPartnerModal || showInviteUserWithPartnerModal" for="modals">
            <partner-connect-modal
                v-if="showConnectToPartnerModal"
                :show-modal="showConnectToPartnerModal"
                @close="showConnectToPartnerModal = false"
                resource="userId"
                :resourceId="userInModal?.id"
                @connectToPartner="onConnectToPartner" />

            <user-invite-partner-tally
                v-if="showInviteUserWithPartnerModal"
                :tally-form-url="'https://tally.so/r/waG5VX'"
                :show-modal="showInviteUserWithPartnerModal"
                @close="showInviteUserWithPartnerModal = false" />
        </slot>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AdminPortal } from '@frontegg/vue';
import { BUTTONS, PATIENT_MANAGEMENT } from '@/utils/constants';
import UsersTable from '../components/users-table';
import PartnerConnectModal from '@/components/Modals/partner-connect-modal.vue';
import UserInvitePartnerTally from '@/components/Modals/user-invite-partner-tally.vue';

export default {
    components: { UsersTable, PartnerConnectModal, UserInvitePartnerTally },
    data() {
        return {
            BUTTONS,
            PATIENT_MANAGEMENT,
            enableAddModal: false,
            showConnectToPartnerModal: false,
            userInModal: null,
            showInviteUserWithPartnerModal: false
        };
    },
    computed: {
        ...mapGetters({
            allPatients: 'user/allPatients',
            loggedUser: 'user/currentUser',
            page: 'user/patientPage',
            pageSize: 'user/patientPageSize',
            total: 'user/patientTotal',
            queryString: 'user/patientQueryString'
        }),
        isLoading() {
            return !!this.$store.state.loading['user/getAllPatients'];
        },
        isDev() {
            return process.env.VUE_APP_ENVIRONMENT === 'development' || process.env.VUE_APP_ENVIRONMENT === 'local';
        },
        isLocalHost() {
            return window.location.hostname === 'localhost';
        }
    },
    async created() {
        await this.getAllPatients();
    },
    methods: {
        ...mapActions({
            getAllPatients: 'user/getAllPatients',
            createPartnerConnection: 'partner/createPartnerConnection'
        }),
        showAdminPortal(url) {
            AdminPortal.show();
            // eslint-disable-next-line no-return-assign
            setTimeout(() => (window.location.href = url), 200);
        },
        viewUserInfo(user) {
            this.$router.push({ name: 'user-info', params: { userId: user.id } });
        },
        async onPageChange({ page, queryString }) {
            await this.getAllPatients({
                page,
                pageSize: this.pageSize,
                queryString
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllPatients({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm
            });
        },
        async onConnectToPartner(payload) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                await this.createPartnerConnection(payload);

                this.$notify({
                    type: 'success',
                    text: 'User connected to partner successfully'
                });

                this.userInModal = null;
                this.showConnectToPartnerModal = false;
            } catch (error) {
                console.error('Error connecting user:', error);
            }
        }
    }
};
</script>
