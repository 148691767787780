<template>
    <div class="width-max-content">
        <label v-if="label" class="d-block text-muted text-sm font-weight-bold mb-2 w-100 text-left">{{ label }}</label>
        <div class="toggle-buttons-box">
            <button
                v-for="(option, index) in options"
                :key="`${index}_${parentKey}`"
                :class="[
                    selectedOption && selectedOption[trackBy] === option[trackBy] ? 'selected' : '',
                    !index ? 'left' : index === options.length - 1 ? 'right' : 'border-right-0'
                ]"
                :disabled="isDisabled"
                class="toggle-button"
                type="button"
                @click="onClick(option)">
                {{ option[labelKey] }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        parentKey: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        selected: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        allowDeselect: {
            type: Boolean,
            default: false
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        trackBy: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            selectedOption: null
        };
    },
    created() {
        this.selectedOption = this.options.find((option) => option[this.trackBy] === this.selected);
    },
    methods: {
        onClick(option) {
            if (this.selectedOption !== option) {
                this.selectedOption = option;
                this.$emit('toggle-changed', this.selectedOption);
            } else if (this.allowDeselect) {
                this.selectedOption = '';
                this.$emit('toggle-changed', this.selectedOption);
            }
        }
    }
};
</script>

<style lang="scss">
.toggle-buttons-box {
    position: relative;
    width: fit-content;
    border-radius: 0.25rem;
    min-width: 134px;
}

.toggle-button {
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    background: #fff;
    outline: none;
    position: relative;
    text-align: center;
    border: 1px solid #dee2e6;
    color: #b7b7b7;
    font-size: 0.7rem;

    &.left {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        margin-right: -1px;
    }

    &.right {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    &.selected {
        z-index: 1;
        border: 1px solid #8a459a;
        color: #8a459a;
    }

    &:disabled {
        pointer-events: none;
        border: 1px solid #dee2e6;
        color: #6c757d;
        background-color: #e9ecef;
    }
}

@media (min-width: 992px) {
    .toggle-button {
        padding: 0.375rem 1.5rem;
        font-size: 0.75rem;
    }
}
</style>
