<template>
    <loader :listen="['auth']" :force-height="'95vh'">
        <div id="app">
            <span
                v-if="!isOnline"
                class="alert alert-danger"
                style="position: fixed; top: 2.75rem; text-align: center; width: 100%; left: 0; padding: 0.45rem 1.25rem">
                <small style="padding: 1rem">YOU ARE OFFLINE. THERE IS NO INTERNET CONNECTION DETECTED.</small>
            </span>
            <vue-confirm-dialog class="vue-confirm-dialog" />
            <div
                v-if="noBENeeded || userHasAccess"
                :class="{
                    'small-left-p': userHasAccess && !isSidenavOpen,
                    'large-left-p': userHasAccess && isSidenavOpen
                }">
                <div class="mobile-top-component" />
                <sidenav v-if="userHasAccess" :is-open="isSidenavOpen" :hide-for-patient="!showRouterAndApp" @toggle="toggleSidebar" />
                <!-- <b-navbar
                    id="mainNavbar"
                    style="background-color: #8A459A !important; box-shadow: 0 5px 15px 0 rgb(0 0 0 / 20%);"
                    toggleable="lg" variant="light" fixed="top">
                    <b-navbar-nav class="w-100">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="w-100 text-left d-flex">
                                <img alt="Your Performance Lab" width="150" src="./assets/img/logo_white.svg">
                                <h6 v-if="trialEndingOn" class="m-0 ml-3 p-0">
                                    <b-badge variant="info" size="lg">
                                        TRIAL
                                        <br>
                                        <small>until {{ trialEndingOn }}</small>
                                    </b-badge>
                                </h6>
                            </div>
                            <div class="text-right sign-out-icon w-100" @click="logout()">
                                <span class="clickable p-2 sign-out"><font-awesome-icon icon="fas fa-sign-out-alt" />
                                </span>
                            </div>
                        </div>
                    </b-navbar-nav>
                </b-navbar> -->
                <main>
                    <!-- <div v-if="userHasAccess && needsOnboarding" class="p-5">
                        <user-quiz :quiz-id="onboardingQuizKey" @quiz-completed="onboardingDone = true" />
                    </div> -->
                    <div>
                        <div class="vm-container" :style="user?.readOnly ? 'padding-top: 30px !important;' : ''">
                            <div
                                class="vm-content"
                                :style="showDoctorActions ? 'padding-bottom: 30rem !important;' : ''"
                                @scroll="isScrollToTopDisplayed">
                                <div class="m-auto content-width" :style="!showRouterAndApp ? 'height: 100%' : ''">
                                    <button
                                        v-if="isScrolling"
                                        id="btn-back-to-top"
                                        :style="!user.registrationOngoing ? 'bottom: 70px;' : 'bottom: 10px;'"
                                        type="button"
                                        class="btn btn-primary btn-floating btn-lg"
                                        @click="$scrollTop">
                                        <i class="fas fa-arrow-up" />
                                    </button>
                                    <!-- I think we should remove this, it's not suitable for web -->
                                    <div v-if="showInAppNavButtons" class="d-flex justify-content-between">
                                        <div class="d-flex justify-content-start">
                                            <router-link
                                                v-if="$routerHistory.hasPrevious()"
                                                class="btn btn-link"
                                                style="min-width: 0 !important"
                                                :to="{ path: $routerHistory.previous().path }">
                                                <font-awesome-icon icon="fa fa-arrow-left" class="fa-lg" />
                                            </router-link>
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <router-link
                                                v-if="$routerHistory.hasForward()"
                                                class="btn btn-link"
                                                style="min-width: 0 !important"
                                                :to="{ path: $routerHistory.next().path }">
                                                <font-awesome-icon icon="fa fa-arrow-right" class="fa-lg" />
                                            </router-link>
                                        </div>
                                    </div>
                                    <transition v-if="showRouterAndApp" name="fade" mode="out-in">
                                        <router-view />
                                    </transition>
                                    <div v-else class="d-flex align-items-center justify-content-center">
                                        <h5>Web application is not available yet. Please use the mobile app.</h5>
                                    </div>
                                </div>
                            </div>
                            <!-- I think we should remove this, it's not suitable for web -->
                            <!-- <SwipeBottomNavigation
                                v-if="!user.registrationOngoing" v-model="selected" :options="links"
                                :swiper-color="primaryColor" background-color="#FFFFFF"
                                :replace-route="true" :icon-color="primaryColor" /> -->
                            <doctor-actions
                                v-if="showDoctorActions"
                                :expanded="doctorActionsState"
                                :style="isSidenavOpen ? 'width: 83vw' : 'width: 94vw'"
                                @toggleActions="setDoctorActionsState" />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </loader>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { socketioService } from './services/socketio.service';
import { greyColor, primaryColor } from './utils/constants';
import sidenav from './components/sidenav.vue';
import doctorActions from './components/doctor-actions';

export default {
    components: {
        sidenav,
        doctorActions
    },
    /* TODO: needs some refactoring */
    data() {
        return {
            ...this.mapAuthState(),
            ...this.mapTenantsState(),
            isScrolling: false,
            isSidenavOpen: false,
            element: document.querySelector('.vm-content'),
            selected: 1,
            isOnline: window.navigator.onLine,
            doctorActionsState: false,
            grey: greyColor,
            primaryColor
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            result: 'result/currentResult'
        }),
        ...mapState({
            authLoading: (state) => state.loading.auth
        }),
        isLogout() {
            return this.$route.path.includes('logout');
        },
        isRedirect() {
            return this.$route.path.includes('redirect');
        },
        isPartnerInvitation() {
            return this.$route.path.includes('user-invitation');
        },
        noBENeeded() {
            return this.$route.meta.noBE;
        },
        showDoctorActions() {
            const idType = this.$route.query.idType || 'result';
            const resultId = this.$route.query.resultId || this.$route.params.resultId || idType === 'result' ? this.$route.params.id : null;
            const orderId = this.$route.query.orderId || this.$route.params.orderId || idType === 'order' ? this.$route.params.id : null;

            return (
                (this.user.wantDoctorReview ? this.result.doctorHasReviewed : true) &&
                (this.user.canDoPartnerApproval || this.user.canDoDoctorReview) &&
                !_.isEmpty(this.result) &&
                !_.isNil(this.result) &&
                (resultId || orderId) &&
                this.$route.meta.showDoctorActions &&
                (this.result.id == resultId || this.result.orderId == orderId)
            );
        },
        showRouterAndApp() {
            return (
                this.isPartnerInvitation ||
                this.isRedirect ||
                this.isLogout ||
                this.user.isAdmin ||
                this.user.isDoctor ||
                this.user.isPartner ||
                this.user.isPartnerPlus
            ); // || isDevelop
        },
        showInAppNavButtons() {
            return !this.isLogout && !this.$route.path.includes('signup') && !this.$route.path.includes('onboard');
        },
        userHasAccess() {
            return this.fronteggLoaded && this.authState.isAuthenticated && this.authState?.user?.accessToken && this.user.id;
        }
    },
    watch: {
        'authState.user': {
            immediate: true,
            async handler(val, oldVal) {
                if (val) {
                    this.setIsAuthenticated(this.authState.isAuthenticated);
                }

                if (!this.$route.meta.noBE && val && val.accessToken && val?.accessToken !== oldVal?.accessToken) {
                    try {
                        await this.auth();
                        if (!this.user.id) {
                            const timeoutErrorMessage = 'Error while checking the user status. Please try again later.';
                            throw new Error(timeoutErrorMessage);
                        }
                    } catch (error) {
                        this.$notify({
                            type: 'error',
                            text: error
                        });
                    }

                    if (this.user?.id && this.showRouterAndApp) {
                        this.setupConnections(this.user);
                    }

                    if (!!this.user?.id && !this.showRouterAndApp) {
                        this.$router.push('/logout');
                    }
                }
            }
        },
        user: {
            immediate: true,
            async handler(val, oldVal) {
                if (this.showRouterAndApp && val && val.id && val.id !== oldVal?.id && !socketioService.socket) {
                    // one time in the session, when auth happens
                    this.setupConnections(val);
                }
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.isScrollToTopDisplayed);
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        if (window) {
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
        }
        if (socketioService.socket) {
            socketioService.offAny();
            socketioService.disconnect();
        }
    },
    destroyed() {
        const element = document.querySelector('.vm-content div');
        if (element) element.removeEventListener('scroll', this.isScrollToTopDisplayed);
    },
    methods: {
        toggleSidebar() {
            this.isSidenavOpen = !this.isSidenavOpen;
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.isOnline = type === 'online';
        },
        ...mapActions({
            auth: 'auth/auth',
            setIsAuthenticated: 'auth/setIsAuthenticated',
            getAllResults: 'result/getAllResults',
            getAllOrders: 'order/getAllOrders',
            getAllPatients: 'user/getAllPatients',
            getDashboardBasicMetrics: 'dashboard/getDashboardBasicMetrics',
            getProfile: 'user/getProfile'
        }),
        isScrollToTopDisplayed(e) {
            this.isScrolling = e.target.scrollTop > 50; // && (top > window.innerHeight);
        },
        instantiateSocketIoListeners(user) {
            socketioService.socket.on(`connected_${user.id}`, () => {
                console.log('connected to websocket - response from server');
            });

            // socketioService.socket.on('fetch_user_profile', () => {
            //     console.log('fetch profile');
            //     this.getProfile();
            // });
            // socketioService.socket.on(`order_update_${user.id}`, (orderId) => {
            //     console.log(orderId, 'an order has been updated - id came');
            //     Promise.all([this.getAllUserOrders(user.id), this.getAllResultsForAUser(user.id)]);
            // });

            // socketioService.socket.on(`order_results_update_${user.id}`, (orderId) => {
            //     console.log(orderId, 'result for an order has been updated - id came');
            //     Promise.all([
            //         this.getAllUserOrders(user.id),
            //         this.getAllResultsForAUser(user.id),
            //         this.getUserBloodHealthSummary({
            //             userId: user.id,
            //             last: this.user.defaultFilters.blood.last,
            //         }),
            //         this.getBloodHealthInsightsForAUser({
            //             userId: user.id,
            //             last: this.user.defaultFilters.blood.last,
            //         }),
            //       //  this.getLolaScores(),
            //     ]);
            // });

            socketioService.socket.on(`logged_user_update_${user.id}`, () => {
                console.log('user profile needs to be updated. Updating...');
                this.getProfile();
            });

            // socketioService.socket.on(`order_new_${user.id}`, (orderId) => {
            //     console.log(orderId, 'new order - id came');
            //     Promise.all([this.getAllUserOrders(user.id), this.getAllResultsForAUser(user.id)]);
            // });

            // socketioService.socket.on(`order_new_${user.id}`, (orderId) => {
            //     console.log(orderId, 'new order - id came');
            //     Promise.all([this.getAllUserOrders(user.id), this.getAllResultsForAUser(user.id)]);
            // });

            // socketioService.socket.on(`ypl_plan_generated_${user.id}`, (plan) => {
            //     this.$notify({
            //         type: 'success',
            //         text: `${plan.type} plan was successfully generated!`,
            //     });
            // });

            // socketioService.socket.on('openai_error', () => {
            //     this.$notify({
            //         type: STATUS_TYPES.error,
            //         text: LOLA_BUDDY.regenerateErrorMessage,
            //     });
            // });

            // socketioService.socket.on('error_regenerate', this.updateLoading(false));
            // socketioService.socket.on('daily_task_updated', this.handleTaskUpdate);

            if (this.user.isDoctor || this.user.isAdmin || this.user.isPartner || this.user.isPartnerPlus) {
                socketioService.socket.on(`order_doctor_review_${user.id}`, async (orderId) => {
                    console.log(orderId, 'order id came');

                    if ((this.user.isAdmin || this.user.isPartnerPlus) && !this.$store.state.loading['order/getAllOrders']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if ((this.user.isAdmin || this.user.isPartnerPlus) && !this.$store.state.loading['order/getAllOrders'])
                                this.getAllOrders({ withLoading: false });
                        }, 5000);
                    }

                    if (!this.$store.state.loading['result/getAllResults']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if (!this.$store.state.loading['result/getAllResults']) {
                                this.getAllResults({ withLoading: false });
                            }
                        }, 5000);
                    }
                });

                socketioService.socket.on('results_or_orders_updated', async () => {
                    console.log('results or orders updated');

                    if (!this.$store.state.loading['dashboard/getDashboardBasicMetrics']) {
                        setTimeout(() => {
                            if (!this.$store.state.loading['dashboard/getDashboardBasicMetrics']) {
                                this.getDashboardBasicMetrics({
                                    filter: {
                                        value: 'all-time',
                                        type: 'custom'
                                    },
                                    forceLoad: true,
                                    withoutLoading: true
                                });
                            }
                        }, 5000);
                    }

                    if ((this.user.isAdmin || this.user.isPartnerPlus) && !this.$store.state.loading['order/getAllOrders']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if ((this.user.isAdmin || this.user.isPartnerPlus) && !this.$store.state.loading['order/getAllOrders'])
                                this.getAllOrders({ withLoading: false });
                        }, 5000);
                    }

                    if (!this.$store.state.loading['result/getAllResults']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if (!this.$store.state.loading['result/getAllResults']) this.getAllResults({ withLoading: false });
                        }, 5000);
                    }
                });

                socketioService.socket.on('fetch_patients', async () => {
                    console.log('fetch patients');
                    if (!this.$store.state.loading['dashboard/getDashboardBasicMetrics']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if (!this.$store.state.loading['dashboard/getDashboardBasicMetrics']) {
                                this.getDashboardBasicMetrics({
                                    filter: {
                                        value: 'all-time',
                                        type: 'custom'
                                    },
                                    forceLoad: true,
                                    withoutLoading: true
                                });
                            }
                        }, 5000);
                    }

                    if (!this.$store.state.loading['user/getAllPatients']) {
                        // wait 5 seconds
                        setTimeout(() => {
                            if (!this.$store.state.loading['user/getAllPatients']) this.getAllPatients({ withLoading: false });
                        }, 5000);
                    }
                });
            }
        },
        setDoctorActionsState(event) {
            this.doctorActionsState = event;
        },
        async setupConnections(user) {
            try {
                if (!user) return;
                if (socketioService.socket) {
                    await socketioService.close();
                    await socketioService.disconnect();
                }

                socketioService.socket = await socketioService.setupSocketConnection(user.id || null);

                socketioService.socket.on('refresh_browser', () => {
                    window.location.reload();
                });

                if (user.id) {
                    // if (!window.location.href.includes('localhost')) {
                    //     posthog.identify({
                    //         distinctId: user.id,
                    //         properties: {
                    //             name: user.name,
                    //             email: user.email,
                    //         },
                    //     });

                    //     Sentry.setUser({ id: user.id, email: user.email });
                    // }
                    await this.$OneSignal.login(user.id);
                    this.instantiateSocketIoListeners(user);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
};
</script>

<style lang="scss">
@import './styles/variables';
@import './styles/sidenav';

.sm-btn-title {
    font-size: 12px !important;
}

.vue-confirm-dialog {
    .vc-container {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        max-width: 450px;
    }

    .vc-text-grid {
        font-family: 'Poppins';
    }

    .vc-btn-grid {
        font-family: 'Poppins';

        .vc-btn {
            font-weight: 500;
            font-size: 14px;
            color: #8a459a;
        }

        .left {
            color: #2c3e50;
        }
    }
}

#btn-back-to-top {
    position: absolute;
    // min-width: 54px;
    width: 35px;
    padding: 10px;
    right: 20px;
    z-index: 10000;
    box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.04);
}

.sign-out-icon {
    color: white !important;
}

.sign-out {
    border-radius: 5px;

    &:hover {
        background-color: #4c2156 !important;
    }
}

.nav-link {
    padding: 3px !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.content-width {
    width: clamp(65%, 100%, 100%) !important;
    max-width: 1980px;
}

.mobile-top-component {
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: sticky;
        top: 0;
        background: #fefefe;
        opacity: 0.5;
        height: 62px;
        width: 100%;
    }
}
</style>
