<template>
    <b-modal id="edit-modal" v-model="modalVisible" @hidden="cancel">
        <template #modal-header="{ close }">
            <h5>Edit Partner</h5>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
        </template>

        <template #default v-if="editedValues">
            <!-- Name Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="name" class="col-12 col-form-label">Name</label>
                <div class="col-12">
                    <input id="name" v-model="editedValues.name" name="name" placeholder="Full Name" class="form-control here" type="text" />
                    <small v-if="nameError" class="text-danger">{{ nameError }}</small>
                </div>
            </div>

            <!-- Email Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="email" class="col-12 col-form-label">Email</label>
                <div class="col-12">
                    <input id="email" v-model="editedValues.email" name="email" placeholder="Email" class="form-control here" type="email" />
                    <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                </div>
            </div>

            <!-- Role Selection with Descriptions -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="role" class="col-12 col-form-label">Role</label>
                <div class="col-12">
                    <b-form-group>
                        <b-form-radio v-model="editedValues.role" name="role" :value="Roles.PARTNER" @change="handleRoleChange(Roles.PARTNER)">
                            Partner
                        </b-form-radio>
                        <small v-if="editedValues.role === Roles.PARTNER" class="text-muted"
                            >Partner gets only notifications and basic dashboard view.</small
                        >

                        <b-form-radio
                            v-model="editedValues.role"
                            name="role"
                            :value="Roles.PARTNER_PLUS"
                            @change="handleRoleChange(Roles.PARTNER_PLUS)">
                            Partner-Plus
                        </b-form-radio>
                        <small v-if="editedValues.role === Roles.PARTNER_PLUS" class="text-muted"
                            >Partner-Plus can perform actions as you can select below.</small
                        >
                    </b-form-group>
                    <small v-if="roleError" class="text-danger">{{ roleError }}</small>
                </div>
            </div>

            <!-- Coupon Code Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="partnerCouponCode" class="col-12 col-form-label">Coupon Code</label>
                <div class="col-12">
                    <input
                        id="partnerCouponCode"
                        v-model="editedValues.partnerCouponCode"
                        name="partnerCouponCode"
                        placeholder="Coupon Code"
                        class="form-control here"
                        type="text" />
                    <small v-if="couponCodeError" class="text-danger">{{ couponCodeError }}</small>
                </div>
            </div>

            <!-- Actions Selection (Shown Only for Partner-Plus) -->
            <div v-if="isPartnerPlusSelected" class="form-group d-flex flex-column align-items-center">
                <label for="actions" class="col-12 col-form-label">Actions</label>
                <div class="col-12">
                    <b-form-group>
                        <b-form-checkbox v-model="editedValues.actions" name="actions" :value="UserActionPermissions.DOCTOR_REVIEW">
                            Doctor Review
                        </b-form-checkbox>
                        <b-form-checkbox v-model="editedValues.actions" name="actions" :value="UserActionPermissions.PARTNER_REVIEW">
                            Partner Review
                        </b-form-checkbox>
                    </b-form-group>
                    <small class="text-muted">{{ actionDescription }}</small>
                    <small v-if="actionsError" class="text-danger">{{ actionsError }}</small>
                </div>
            </div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-info" @click="cancel">Cancel</button>
            <loading-btn class="btn btn-primary" :disabled="disableSaveButton" :listen="'partner/updatePartner'" @click="submit">
                Save Changes
            </loading-btn>
        </template>
    </b-modal>
</template>

<script>
import { Roles, UserActionPermissions, ACTION_DESCRIPTIONS } from '@/utils/constants';
import _ from 'lodash';

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        initialData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            modalVisible: false,
            editedValues: null,
            originalValues: null, // Store original data to compare
            Roles,
            UserActionPermissions,
            ACTION_DESCRIPTIONS
        };
    },
    computed: {
        isPartnerPlusSelected() {
            return this.editedValues.role === Roles.PARTNER_PLUS;
        },
        actionDescription() {
            if (
                this.editedValues.actions.includes(UserActionPermissions.DOCTOR_REVIEW) &&
                this.editedValues.actions.includes(UserActionPermissions.PARTNER_REVIEW)
            ) {
                return ACTION_DESCRIPTIONS.BOTH;
            } else if (this.editedValues.actions.includes(UserActionPermissions.DOCTOR_REVIEW)) {
                return ACTION_DESCRIPTIONS.DOCTOR_REVIEW;
            } else if (this.editedValues.actions.includes(UserActionPermissions.PARTNER_REVIEW)) {
                return ACTION_DESCRIPTIONS.PARTNER_REVIEW;
            } else {
                return '';
            }
        },
        nameError() {
            return !this.editedValues.name ? 'Name is required.' : '';
        },
        emailError() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return this.editedValues.email && !emailPattern.test(this.editedValues.email) ? 'Please enter a valid email address.' : '';
        },
        roleError() {
            return !this.editedValues.role ? 'Please select a role.' : '';
        },
        couponCodeError() {
            const couponPattern = /^[a-zA-Z0-9]+$/;
            return this.editedValues.partnerCouponCode && !couponPattern.test(this.editedValues.partnerCouponCode)
                ? 'Coupon code should be alphanumeric only.'
                : '';
        },
        actionsError() {
            return this.isPartnerPlusSelected && this.editedValues.actions.length === 0 ? 'At least one action is required for Partner-Plus.' : '';
        },
        disableSaveButton() {
            return !!(
                this.nameError ||
                this.emailError ||
                this.roleError ||
                this.couponCodeError ||
                this.actionsError ||
                _.isEqual(this.editedValues, this.originalValues)
            );
        }
    },
    mounted() {
        this.editedValues = _.cloneDeep({
            name: this.initialData.profileData?.name || '',
            email: this.initialData.email || '',
            role: this.initialData.roles?.find((r) => r.key === Roles.PARTNER_PLUS || r.key === Roles.PARTNER)?.key, // todo - test if multiple roles are possible
            partnerCouponCode: this.initialData.metadata?.partnerCouponCode || '',
            actions: this.initialData.metadata?.actions || []
        });

        this.originalValues = _.cloneDeep(this.editedValues);
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        handleRoleChange(role) {
            if (role === Roles.PARTNER) {
                this.editedValues.actions = []; // Clear actions if switched to Partner
            }
        },
        submit() {
            if (!this.disableSaveButton) {
                const changedValues = {};

                // Check for changes and add only changed values to the payload
                for (const key in this.editedValues) {
                    if (JSON.stringify(this.editedValues[key]) !== JSON.stringify(this.originalValues[key])) {
                        changedValues[key] = this.editedValues[key];
                    }
                }

                this.$emit('updatePartner', changedValues);
            }
        },
        cancel() {
            this.$emit('close');
            this.editedValues = JSON.parse(JSON.stringify(this.originalValues)); // Reset changes on cancel
        }
    }
};
</script>
