<template>
    <fragment>
        <div v-if="options && options.length" class="desktop-filter-dropdown my-3">
            <div class="btn-group btn-group-toggle filter-button-group" data-toggle="buttons">
                <label v-for="option in options" :key="option.id" class="btn btn-primary btn-radio m-2" :class="{ active: isActive(option) }">
                    <input v-if="!option.url" v-model="activeTab" type="radio" :value="option.id" name="options" />
                    <div class="d-flex justify-content-center w-100" style="align-items: baseline !important">
                        <router-link v-if="option.url" tag="div" :to="option.url" class="w-100">{{ option.label }}</router-link>
                        <div v-else>{{ option.label }}</div>
                        <span v-if="showCount && option.count" class="badge badge-light ml-2">{{ option.count }}</span>
                    </div>
                </label>
            </div>
        </div>

        <div v-if="options && options.length" class="mobile-filter-dropdown m-3">
            <b-dropdown
                :text="`${selectedOption?.label}${showCount && selectedOption?.count ? ` - ${selectedOption.count}` : ''}`"
                class="mt-3"
                variant="light">
                <b-dropdown-item v-for="option in options" :key="option.id" :active="isActive(option)" @click="activeTab = option.id">
                    <div class="d-flex align-items-center justify-content-center">
                        <div>{{ option?.label }}</div>
                        <span v-if="showCount && option.count" class="badge badge-light ml-2">{{ option.count }}</span>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </fragment>
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        defaultOption: {
            type: Object,
            default: null
        },
        showCount: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeTab: ''
        };
    },
    computed: {
        isActive() {
            return (option) => (option.url ? this.$route.path === option.url : option.id === this.activeTab);
        },
        selectedOption() {
            return this.options.find((option) => this.isActive(option));
        }
    },
    watch: {
        activeTab: {
            handler(optionId) {
                const option = this.options.find((o) => o.id === optionId);

                if (option.url && this.$route.path !== option.url) {
                    this.$router.push(option.url);
                } else {
                    this.$emit('changed', optionId);
                }
            }
        }
    },
    created() {
        const selected = this.defaultOption || this.options[0];
        if (!selected.url) {
            this.activeTab = selected.id;
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile-filter-dropdown {
    display: none;

    @media screen and (max-width: 778px) {
        display: block;
    }
}

.desktop-filter-dropdown {
    display: block !important;

    @media screen and (max-width: 778px) {
        display: none !important;
    }
}
</style>
