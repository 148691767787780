/* eslint-disable no-unused-vars */
import api from './api';

export default {
    async getUser(id) {
        const response = await api.get(`user/get/${id}`);
        return response;
    },
    async getAllPatients({ page = 1, page_size = 20, query_string = null }) {
        const response = await api.post('user/all/patient', {
            page,
            page_size,
            query_string
        });

        return response;
    },
    async getAllPartners({ page = 1, page_size = 20, query_string = null }) {
        const response = await api.post('user/all/partner', {
            page,
            page_size,
            query_string
        });

        return response;
    },
    async getProfile() {
        const response = await api.get('user/profile/get');
        return response;
    },
    async updateProfileHealthcareQuestionnaire(info) {
        const response = await api.post('user/profile/update/quiz', { healthcareQuestionnaire: info });
        return response;
    },
    async updateUser(user) {
        const response = await api.post('user/update', user);
        return response;
    },
    async signupPatient(user) {
        const response = await api.post('user/signup-patient', user);
        return response;
    },
    async updateProfile(newUserDetails) {
        const response = await api.post('user/profile/update', newUserDetails);
        return response;
    },
    async initiateWearableConnection(userId) {
        const nativeConnected = localStorage.getItem('native-connection') === 'true' || localStorage.getItem('native-connection') === true;

        const payload = {
            userId,
            redirectToSuccess: nativeConnected ? `yplabs://wear-connection-${userId}` : window.location.href,
            redirectToFail: nativeConnected ? `yplabs://wear-connection-${userId}` : window.location.href
        };

        const response = await api.post('wear/connect', payload);
        return response;
    },
    async disconnectWearable(deviceId) {
        const payload = {
            deviceId
        };

        await api.post('wear/disconnect', payload);
    },

    async deleteWearable(deviceId) {
        const payload = {
            deviceId
        };

        await api.post('wear/delete', payload);
    },

    async getWearableData(deviceId, dateFilter) {
        const response = await api.get(`wear/info/${deviceId}`);

        return response;
    },

    async getAllWearableDataForUser(userId, dateFilter) {
        const response = await api.get(`wear/all-info/${userId}`);

        return response;
    },

    async updateWearableOnAUser(userId, info) {
        delete info.icon;
        delete info.dateAdded;

        const response = await api.post(`wear/update-internal/${userId}`, info);

        return response;
    },

    async getUserLolaScores(userId = null) {
        const response = userId
            ? await api.get(`user/ypl-scores/${userId}`, { 'with-blood-score': true })
            : await api.get('user/profile/ypl-scores', { 'with-blood-score': true });

        return response;
    }
};
