/* eslint-disable max-len */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import Fragment from 'vue-fragment';
import HighchartsVue from 'highcharts-vue';
import PortalVue from 'portal-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsRegression from 'highcharts-regression';
import dataModule from 'highcharts/modules/data';
import Multiselect from 'vue-multiselect';
import VueCountryCode from 'vue-country-code';
import VueConfirmDialog from 'vue-confirm-dialog';
import OneSignalVue from 'onesignal-vue';
import bottomNavigationVue from 'bottom-navigation-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueSanitize from 'vue-sanitize';
import * as Sentry from '@sentry/vue';
import { Frontegg } from '@frontegg/vue';
import VueRouterBackButton from 'vue-router-back-button';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faLolaBuddy } from './assets/svg/icons/custom-fa-icons';

import App from './App';
import router from './router';
import store from './store';
import './styles/app.scss';
import './utils/notify';
import './utils/validators';
import './registerServiceWorker';
import './directives';
import Loader from './components/global/loaders/loader';
import LoadingBtn from './components/global/buttons/loading-btn';
import { FronteggPlugin } from './utils/auth.instance';
import { insertZendeskScript } from './services/zendesk.service';

Vue.use(PortalVue);
Vue.use(OneSignalVue);
Vue.use(VCalendar);
Vue.use(Fragment.Plugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(bottomNavigationVue);
Vue.component('Multiselect', Multiselect);
Vue.component('Loader', Loader);
Vue.component('LoadingBtn', LoadingBtn);

HighchartsMore(Highcharts);
HighchartsRegression(Highcharts);
dataModule(Highcharts);
Vue.use(HighchartsVue);
Vue.use(Vuelidate);
library.add(faSignOutAlt);
library.add(fas);
library.add(faLolaBuddy);
dom.watch();
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.use(VueConfirmDialog);
Vue.component('VueConfirmDialog', VueConfirmDialog.default);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueCountryCode);

Vue.use(VueRouterBackButton, { router });

const defaultSanitizeOptions = {
    allowedAttributes: {
        '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style']
    },
    allowedClasses: {
        '*': ['*-quill', 'quill-*', 'quill', '*-quill-*', '*-ql', 'ql-*', 'ql', '*-ql-*']
    },
    allowedStyles: {
        '*': {
            // Match HEX and RGB
            color: [/^#(0x)?[0-9a-f]+$/i, /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/],
            'text-align': [/^left$/, /^right$/, /^center$/],
            // Match any number with px, em, or %
            'font-size': [/^\d+(?:px|em|%)$/],
            'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
        }
    }
};
Vue.use(VueSanitize, defaultSanitizeOptions);

if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage('native-handshake');

window.addEventListener(
    'com.yplabs.handshake',
    () => {
        localStorage.setItem('native-connection', true);
        console.log('user connected from native app');
    },
    true
);

window.addEventListener(
    'com.yplabs.user',
    (message) => {
        const details = message.data;
        console.log('user connected from native app', details);
    },
    true
);

Vue.prototype.$scrollTop = () => {
    const element = document.querySelector('.vm-content');

    // const top = element.offsetTop;

    if (element) element.scrollTo({ top: 0, behavior: 'smooth' });
};

Vue.prototype.$emitToNative = (data) => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(typeof data === 'string' ? data : JSON.stringify(data));
};

Vue.prototype.$isNativeConnected = () => {
    return localStorage.getItem('native-connection') === 'true' || localStorage.getItem('native-connection') === true;
};

if (!window.location.href.includes('localhost')) {
    Sentry.init({
        Vue,
        dsn: 'https://1996b1310bba4d82831f8afee8d455be@o4504473785860096.ingest.sentry.io/4504473794117632',
        integrations: [
            new Sentry.browserTracingIntegration({
                router
            })
            // new posthog.SentryIntegration(posthog, 'Your Performance Lab, Inc.', 26479),
        ],
        environment: process.env.VUE_APP_ENVIRONMENT,
        tracingOptions: {
            trackComponents: true
        },
        tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
        debug: false,
        attachProps: true,
        attachStacktrace: true,
        logErrors: process.env.VUE_APP_ENVIRONMENT !== 'production'
        // tracePropagationTargets: ['localhost', 'app.dev.yplabs.com', 'app.prod.yplabs.com', 'app.yplabs.com', /^\//],
    });
}

Vue.use(Frontegg, {
    contextOptions: {
        baseUrl: process.env.VUE_APP_FRONTEGG_URL,
        clientId: process.env.VUE_APP_FRONTEGG_CLIENT_ID
    },
    authOptions: {
        keepSessionAlive: true
    },
    hostedLoginBox: true,
    router
});

Vue.use(FronteggPlugin);

insertZendeskScript();

new Vue({
    router,
    store,
    async beforeMount() {
        // TO DO - see on mobile native app
        const mobile = localStorage.getItem('native-connection') === 'true' || localStorage.getItem('native-connection') === true;
        const redirectPage = window.location.href.includes('redirect');

        if (!mobile && !redirectPage) {
            try {
                await this.$OneSignal.init({
                    appId: process.env.VUE_APP_ONESIGNAL_WEB_APP_ID,
                    safari_web_id: process.env.VUE_APP_ONESIGNAL_SAFARI_WEB_ID,
                    autoResubscribe: true,
                    promptOptions: {
                        slidedown: {
                            enabled: true,
                            prompts: [
                                {
                                    type: 'push', // current types are "push" & "category"
                                    autoPrompt: true,
                                    text: {
                                        /* limited to 90 characters */
                                        actionMessage:
                                            'Please allow us to send you notifications regarding orders, blood results & health insights. You can change these settings at any point in your profile page.',
                                        /* acceptButton limited to 15 characters */
                                        acceptButton: 'Allow',
                                        /* cancelButton limited to 15 characters */
                                        cancelButton: "Don't allow"
                                    }
                                }
                            ]
                        }
                    },
                    serviceWorkerPath: 'onesignal/OneSignalSDKWorker.js',
                    serviceWorkerParam: { scope: '/onesignal/' },
                    allowLocalhostAsSecureOrigin: true
                });
            } catch (e) {
                console.log(e);
                throw e;
            }
        }
    },
    render: (h) => h(App)
}).$mount('#app');

axios.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (error?.response) {
            const { status, data } = error.response;
            const currentPath = router.currentRoute.path;
            if (status === 403 && currentPath !== '/home') {
                router.push('/home');
            }

            if (status === 401) {
                // router.push('/logout');
            }
        }

        return Promise.reject(error);
    }
);
