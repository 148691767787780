<template>
    <b-modal id="modal-scoped" v-model="modalVisible" @hidden="cancel">
        <template #modal-header="{ close }">
            <div class="d-flex flex-column">
                <h5>{{ title }}</h5>
                <label class="small pl-3"> {{ description }}</label>
            </div>
            <button size="sm" class="btn btn-sm empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
        </template>

        <template #default>
            <div class="pb-2">
                <span style="white-space: pre-wrap; word-wrap: break-word">
                    {{ text }}
                </span>
            </div>
        </template>
        <template #modal-footer>
            <hr class="m-0 p-0" />
            <button class="btn btn-primary" @click="cancel">Ok</button>
        </template>
    </b-modal>
</template>
<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        text: {
            type: Array,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            modalVisible: false
        };
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        cancel() {
            this.$emit('close');
        }
    }
};
</script>
