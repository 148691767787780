/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { isAuthenticatedGuard } from '@frontegg/vue';
import TenantProfileInfo from '@/components/tenant-profile-info.vue';
import UserProfileInfo from '@/components/user-profile-info.vue';
import Home from '../views/Home';
import Profile from '../views/Profile';
import PatientManagement from '../views/PatientManagement.vue';
import PartnerManagement from '../views/PartnerManagement.vue';
import UserData from '../views/UserDataInfo.vue';
import PartnerInfo from '../views/PartnerInfo.vue';
import OrderManagement from '../views/OrderManagement';
import Result from '../views/Result';
import Logout from '../components/base/logout';
import NotFound404 from '../views/NotFound404.vue';
import Redirect from '../views/Redirect';
import store from '../store';
import ResultsManagement from '../views/ResultsManagement';
import Dashboard from '../views/metrics/Dashboard';
import PartnerUserInvitation from '@/views/PartnerUserInvitation.vue';

Vue.use(VueRouter);

const filterTenantPermissions = (to) => {
    let tenantPermissions = to.meta?.tenantPermissions || [];
    const queries = to.meta?.generalSubscriptionNotNeededCases?.queries || [];

    const { notNeeded } =
        queries.find(({ query = {}, params = {} }) => {
            const queryOk = Object.keys(query).every((key) => to.query[key] === query[key]);
            const paramsOk = Object.keys(params).every((key) => to.params[key] === params[key]);
            return queryOk && paramsOk;
        }) || {};

    // Filter out permissions listed in `notNeeded`, if any
    if (notNeeded) {
        tenantPermissions = tenantPermissions.filter((permission) => !notNeeded.includes(permission));
    }

    return tenantPermissions;
};

const routes = [
    { path: '*', redirect: '/dashboard' },
    { path: '/', redirect: '/dashboard' },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: { auth: ['isDoctor', 'isAdmin', 'isPartner', 'isPartnerPlus'] }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { auth: ['isDoctor', 'isAdmin', 'isPatient', 'isPartner', 'isPartnerPlus'] }
    },
    {
        path: '/results-management',
        name: 'results-management',
        component: ResultsManagement,
        meta: { auth: ['isDoctor', 'isAdmin', 'isPartnerPlus'] }
    },
    {
        path: '/users/:userId',
        name: 'user-info',
        component: UserData,
        meta: { auth: ['isAdmin', 'isDoctor', 'isPartnerPlus'] }
    },
    {
        path: '/partners/:partnerCode',
        name: 'partner-info',
        component: PartnerInfo,
        meta: { auth: ['isAdmin'] }
    },
    {
        path: '/patients',
        name: 'patients',
        component: PatientManagement,
        meta: { auth: ['isAdmin', 'isDoctor', 'isPartnerPlus'] }
    },
    {
        path: '/partners',
        name: 'partners',
        component: PartnerManagement,
        meta: { auth: ['isAdmin'] }
    },
    {
        path: '/order-management',
        name: 'order-management',
        component: OrderManagement,
        meta: { auth: ['isAdmin', 'isPartnerPlus'] }
    },
    {
        path: '/result/:id',
        name: 'result',
        component: Result,
        meta: { auth: ['isAdmin', 'isDoctor', 'isPartnerPlus'], showDoctorActions: true }
    },
    {
        path: '/profile',
        component: Profile,
        meta: { auth: ['isAdmin', 'isDoctor', 'isPartnerPlus'] },
        children: [
            { path: '', name: 'me', component: UserProfileInfo }
            // { path: 'tenant', name: 'tenant', component: TenantProfileInfo }
        ]
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/404-not-found',
        name: '404 Not Found',
        component: NotFound404,
        meta: { noBE: true }
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: Redirect,
        meta: { noBE: true }
    },
    {
        path: '/partner-user-invitation',
        name: 'partnerUserInvitation',
        component: PartnerUserInvitation,
        meta: { noBE: true }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        const element = document.querySelector('.vm-content');
        const top = element ? element.offsetTop : 0;
        if (element) {
            element.scrollTo({ top, behavior: 'smooth' });
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.fullPath !== from.fullPath && !to.meta.noBE) {
        const isAuthenticated = store.state.auth.isAuthenticated;
        if (!isAuthenticated) {
            store.watch(
                (state) => state.auth.isAuthenticated,
                (status) => (status ? isAuthenticatedGuard(to, from, next) : null)
            );
        } else {
            return isAuthenticatedGuard(to, from, next);
        }
    }
    next((error) => error && console.error('Navigation Error:', error));
});

router.beforeResolve((to, from, next) => {
    if (!to.meta.noBE && ((to.fullPath !== from.fullPath && !to.hash.includes('admin-box')) || (!from.name && !to.hash.includes('admin-box')))) {
        const currentUser = store.state.user.user;
        if (!currentUser?.id) {
            store.watch(
                (state) => state.user.user,
                (_user) => generalAccessGuard(to, next, store.getters['user/currentUser'])
            );
        } else {
            return generalAccessGuard(to, next, store.getters['user/currentUser']);
        }
    }

    if (to.meta.noBE) {
        next((error) => error && console.error('Navigation Error:', error));
    }
});

const generalAccessGuard = (to, next, user) => {
    const hasAccess = to.meta.auth ? to.meta.auth.some((role) => user[role]) : true;

    if (!hasAccess) return next('/');

    if (to.meta.tenantPermissions) {
        const tenantPermissions = filterTenantPermissions(to);
        const hasPermission = tenantPermissions.every((permission) => user.hasPermissions(permission));

        if (!hasPermission) return next('/');
    }

    return next();
};

export default router;
