/* eslint-disable import/no-cycle */
import Vue from 'vue';
import api from '../api/dashboard.api';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        dashboardBasicMetrics: {}
    },
    getters: {
        dashboardBasicMetrics(state) {
            return state.dashboardBasicMetrics;
        }
    },
    mutations: {
        setDashboardBasicMetrics(state, metrics) {
            Vue.set(state, 'dashboardBasicMetrics', metrics);
        }
    },
    actions: {
        async getDashboardBasicMetrics({ commit, state }, { filter, forceRefresh, withoutLoading } = {}) {
            if (!withoutLoading) commit('activateLoading', 'dashboard/getDashboardBasicMetrics', { root: true });
            try {
                if (filter.value === 'all-time') {
                    if (!_.isEmpty(state.dashboardBasicMetrics) && !forceRefresh && state.dashboardBasicMetrics?.filter?.value === 'all-time') {
                        return state.dashboardBasicMetrics;
                    }
                }

                const metrics = await api.getMetrics(filter);

                commit('setDashboardBasicMetrics', metrics);

                return metrics;
            } finally {
                if (!withoutLoading) commit('deactivateLoading', 'dashboard/getDashboardBasicMetrics', { root: true });
            }
        }
    }
};
