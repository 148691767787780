<template>
    <loader :listen="['result/getResult', 'user/getProfile']" class="pt-3 pb-5">
        <div v-if="result.result" style="position: relative">
            <h3 class="mb-4 pt-1 text__page-header mt-2">
                <div>
                    {{ result.order.testName }}
                    <div class="d-inline-flex flex-wrap mt-2">
                        <span
                            v-if="result.withoutReview"
                            class="badge badge-pill badge-success mr-2 text-nowrap"
                            style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                            REVIEW NOT NEEDED/INCLUDED
                        </span>
                        <span
                            v-if="result.referrerPartnerCode"
                            class="badge badge-pill badge-info mr-2 text-nowrap"
                            style="font-size: 0.75rem; padding: 0.2rem 0.4rem"
                            v-can-access.role="'admin doctor'">
                            RESULT FROM A PARTNER
                        </span>
                        <span
                            v-if="resultReviewedAndNowAwaitsPartnerApproval"
                            class="badge badge-pill badge-warning text-nowrap"
                            style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                            REVIEWED & AWAITS PARTNER APPROVAL
                        </span>
                        <span
                            v-if="awaitsDoctorReview"
                            class="badge badge-pill badge-warning text-nowrap"
                            style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                            AWAITS DOCTOR REVIEW
                        </span>
                    </div>
                </div>
                <small class="d-flex flex-column">
                    <small>{{ result.displayDateAdded }}</small>
                    <small class="mt-3"><b>ID:</b> {{ result.id }}</small>
                    <small><b>Order ID:</b> {{ result.order.id }}</small>
                </small>
            </h3>

            <!-- <ResultSummary
                v-if="(result && result.resultSummary && result.resultSummary.length)"
                :result="result" :center-on-mobile="true"
                class="mb-3" /> -->

            <div v-if="(result.finished || result.doctorHasReviewed) && result.doctor" class="card mb-4">
                <div class="card-inner analysis-title border-0">
                    <div class="secondary-label text-left pb-1 small">
                        {{ result.displayFinishedDate }}
                    </div>
                    <span v-if="!result.withoutReview"><i class="fa fa-comment fa-lg" /> Comment left by the doctor</span>
                    <span v-else> <i class="fa fa-comment fa-lg" /> Result was approved by the doctor <b>(no review needed)</b> </span>

                    <div class="mt-2 d-flex align-items-center">
                        <img width="30" :src="result.doctor.profilePictureUrl" class="rounded-circle mr-1" />
                        <div>
                            <div>
                                Dr. {{ result.doctor.name }}
                                <span v-if="result.doctor.specialty" class="text-muted">({{ result.doctor.specialty }})</span>
                            </div>
                            <small class="text-muted">{{ result.doctor.email }}</small>
                        </div>
                    </div>

                    <div v-if="result.finished && !showDoctorActions" class="doctor-comment pt-2">
                        <TextEditorViewer :content="result.comment" />
                    </div>
                </div>
                <!-- <TextEditorViewer :content="result.comment" /> -->
                <!-- <div class="card-inner border secondary-label">
                    <div>
                        <img width="30" :src="result.doctor.profilePictureUrl" class="rounded-circle mr-1" />
                        Dr. {{ result.doctor.name }}
                        <span v-if="result.doctor.specialty" class="text-muted">({{ result.doctor.specialty }})</span>
                    </div>
                </div> -->
            </div>
            <div v-can-access.role="'doctor admin partner-plus'" class="text-left pt-4">
                <h6 class="d-flex flex-column align-items-start">
                    <label class="mb-1 pb-0">{{ RESULT.aboutPatient }}</label>
                    <button
                        class="btn btn-link w-auto p-0 m-0"
                        @click.prevent="
                            $router.push({
                                name: 'user-info',
                                params: { userId: result.user.id },
                                query: { resultId: result.id }
                            })
                        ">
                        {{ RESULT.viewUserInfo }}
                    </button>
                </h6>
                <patient-basic-info
                    v-can-access.role="'admin doctor partner-plus'"
                    :result="result"
                    :user="result.user"
                    class="d-flex justify-content-md-center align-items-center w-100 mb-3" />

                <loader :fit-content="true" :listen="['metrics/getAllWearableDataForAUser', 'result/getResult']" class="pt-3 pb-5">
                    <DeviceMetricSummary :metrics="deviceMetricSummary" :date="result.dateForMetricsFetch" />
                </loader>
            </div>
            <div class="my-2">
                <span :class="`btn btn-sm btn-link ${chartView ? '' : 'primary'}`" @click="chartView = false">
                    <i class="fa fa-table fa-lg pr-2" />
                    <div>{{ RESULT.tableView }}</div>
                </span>
                <button :class="`btn btn-link mr-2 ${chartView ? 'primary' : ''}`" @click="chartView = true">
                    <i class="fa fa-chart-line fa-lg pr-2" />
                    <div>{{ RESULT.chartView }}</div>
                </button>

                <button :class="`btn btn-link mr-2`" @click="exportResultToFile">
                    <img src="../../public/assets/svg/icons/pdf.svg" alt="pdf-icon" style="width: 20px; height: 20px" />
                    <div class="ml-2">
                        {{ exportLoading ? 'Exporting...' : 'Export to PDF' }}
                    </div>
                </button>
            </div>
            <!-- <select id="select"
                    v-model="chartView"
                    name="select"
                    class="view-select"
                    required>
                <option :value="false">
                    Table view
                </option>
                <option :value="true">
                    Chart view
                </option>
            </select> -->
            <div v-if="chartView">
                <chart-view-result :categories-with-markers="result.result.OBX" :result="result" />
            </div>
            <div v-else>
                <table-view-result :categories-with-markers="result.result.OBX ? result.result.OBX : {}" :result="result" />
            </div>
        </div>
    </loader>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChartViewResult from '@/components/chart-view-result';
import TableViewResult from '@/components/table-view-result';
import PatientBasicInfo from '@/components/patient-basic-info';
import { ABNORMAL_FLAGS_TAGGED, RESULT, GENERAL } from '@/utils/constants.js';
import TextEditorViewer from '../components/global/editor/text-editor-viewer';
import DeviceMetricSummary from '../components/device-metrics-summary.vue';

export default {
    components: { ChartViewResult, TableViewResult, PatientBasicInfo, DeviceMetricSummary, TextEditorViewer },
    data() {
        return {
            RESULT,
            GENERAL,
            chartView: false,
            actionsState: false,
            ABNORMAL_FLAGS_TAGGED,
            exportLoading: false
        };
    },
    computed: {
        ...mapGetters({
            result: 'result/currentResult',
            user: 'user/currentUser',
            patientDeviceMetricSummary: 'metrics/patientDeviceMetricSummary'
        }),
        userId() {
            return this.$route.query.userId || this.result.user.id || null;
        },
        deviceMetricSummary() {
            return _.compact(_.values(this.patientDeviceMetricSummary[this.userId]).map((item) => item.data[0] || null));
        },
        resultReviewedAndNowAwaitsPartnerApproval() {
            return this.result.doctor && this.result.doctorHasReviewed && !this.result.finished;
        },
        awaitsDoctorReview() {
            return this.user.wantDoctorReview && !this.result.doctorHasReviewed && (this.user.canDoPartnerApproval || !this.user.wantOwnReview);
        },
        showDoctorActions() {
            const idType = this.$route.query.idType || 'result';
            const resultId = this.$route.query.resultId || this.$route.params.resultId || idType === 'result' ? this.$route.params.id : null;
            const orderId = this.$route.query.orderId || this.$route.params.orderId || idType === 'order' ? this.$route.params.id : null;

            return (
                (this.user.wantDoctorReview ? this.result.doctorHasReviewed : true) &&
                (this.user.canDoPartnerApproval || this.user.canDoDoctorReview) &&
                !_.isEmpty(this.result) &&
                !_.isNil(this.result) &&
                (resultId || orderId) &&
                this.$route.meta.showDoctorActions &&
                (this.result.id == resultId || this.result.orderId == orderId)
            );
        }
    },
    async created() {
        if (!this.user) await this.getProfile();
        this.chartView = !this.user.isDoctor && !this.user.isAdmin && !this.user.isPartnerPlus;

        const id = this.$route.params.id;
        const type = this.$route.query.idType;

        let resultId = null;
        let orderId = null;

        if (id) {
            resultId = id;

            if (type === 'order') {
                orderId = id;
                resultId = null;
            }
        }

        if (resultId) {
            if (!this.result || _.isEmpty(this.result) || this.result.id != resultId) await this.getResultWithHistoricalData({ resultId });
        } else if (orderId) {
            if (!this.result || _.isEmpty(this.result) || this.result.orderId != orderId) await this.getResultWithHistoricalData({ orderId });
        }

        const payload = { filter: { type: 'custom', value: 'last30Days', endDate: this.result.dateForMetricsFetch }, timeUnit: 'month' };

        await this.getDeviceMetricSummaryForAPatient({ userId: this.userId, payload });
    },
    methods: {
        ...mapActions({
            getResultWithHistoricalData: 'result/getResultWithHistoricalData',
            getUser: 'user/getUser',
            getProfile: 'user/getProfile',
            exportResult: 'result/exportResult',
            getDeviceMetricSummaryForAPatient: 'metrics/getDeviceMetricSummaryForAPatient'
        }),
        async exportResultToFile() {
            this.exportLoading = true;

            try {
                const file = await this.exportResult({ resultId: this.result.id });

                // Directly use the received file blob
                const url = window.URL.createObjectURL(file.data); // file.data is the blob
                const a = document.createElement('a');
                a.href = url;
                a.download = `${this.result.user?.concatenatedName || 'ID'}_${this.result.id}_result_exported.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            } catch (err) {
                console.log(err, 'err');
            } finally {
                this.exportLoading = false;
            }
        }
    }
};
</script>
<style lang="scss">
.card-body {
    width: 100% !important;
}

.doctor-comment {
    display: flex !important;
    align-items: start !important;
}
</style>
