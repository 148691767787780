import store from '../store';
import { Roles, SubscriptionEntitlementName, UserActionPermissions } from '../utils/constants';

async function checkIfCanAccess(el, { value, modifiers }, vnode) {
    let keep = false;
    const user = store.getters['user/currentUser'];

    const gateType = modifiers.and ? 'and' : 'or'; // Default to "or" if no modifier

    if (modifiers.admin) {
        keep = user.canAccess(Roles.ADMIN);
    }

    if (modifiers.permission && user.hasPermissions(UserActionPermissions[value?.toUpperCase()] || value, gateType)) {
        keep = true;
    }

    if (modifiers.role && user.canAccess(Roles[value?.toUpperCase()] || value, gateType)) {
        keep = true;
    }

    if (modifiers.entitlement && user.hasEntitlements(SubscriptionEntitlementName[value] || value, gateType)) {
        keep = true;
    }

    if (!keep) {
        if (vnode.elm.parentElement) {
            vnode.elm.parentElement.removeChild(vnode.elm);
        } else {
            vnode.elm.remove();
        }
    }
}

export default {
    bind: checkIfCanAccess,
    inserted: checkIfCanAccess,
    componentUpdated: checkIfCanAccess
};
