<template>
    <div>
        <div class="pb-3 w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
            <h4 class="text__page-header mt-2 d-flex justify-content-start">Partner Management</h4>
            <button v-can-access.role="'admin'" @click="showAddPartnerModal = !showAddPartnerModal" class="btn btn-primary mt-2">Add Partner</button>
        </div>
        <div v-if="loggedUser.isAdmin">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <users-table
                        :users="allPartners"
                        :role="Roles.PARTNER_PLUS"
                        @userSelected="viewPartnerInfo"
                        @pageChange="onPageChange"
                        @updateUser="
                            (partner) => {
                                partnerToUpdate = partner;
                                showUpdatePartnerModal = !showUpdatePartnerModal;
                            }
                        "
                        @search="onSearchTerm"
                        :page="page"
                        :pageSize="pageSize"
                        :total="total"
                        :queryString="queryString"
                        :isLoading="isLoading" />
                </div>
            </div>

            <slot v-if="showAddPartnerModal || showUpdatePartnerModal" for="modals">
                <partner-add-modal
                    v-if="showAddPartnerModal"
                    :show-modal="showAddPartnerModal"
                    @addPartner="onAddPartner"
                    @close="showAddPartnerModal = false" />
                <partner-edit-modal
                    v-if="showUpdatePartnerModal"
                    :show-modal="showUpdatePartnerModal"
                    @updatePartner="onUpdatePartner"
                    @close="showUpdatePartnerModal = false"
                    :initialData="partnerToUpdate" />
            </slot>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AdminPortal } from '@frontegg/vue';
import UsersTable from '../components/users-table';
import { Roles } from '@/utils/constants';
import PartnerAddModal from '@/components/Modals/partner-add-modal.vue';
import PartnerEditModal from '@/components/Modals/partner-edit-modal.vue';

export default {
    components: { UsersTable, PartnerAddModal, PartnerEditModal },
    data() {
        return {
            Roles,
            showAddPartnerModal: false,
            showUpdatePartnerModal: false,
            partnerToUpdate: null
        };
    },
    computed: {
        ...mapGetters({
            allPartners: 'user/allPartners',
            loggedUser: 'user/currentUser',
            page: 'user/partnerPage',
            pageSize: 'user/partnerPageSize',
            total: 'user/partnerTotal',
            queryString: 'user/partnerQueryString'
        }),
        isLoading() {
            return !!this.$store.state.loading['user/getAllPartners'];
        }
    },
    async created() {
        await this.getAllPartners();
    },
    methods: {
        ...mapActions({
            getAllPartners: 'user/getAllPartners',
            createPartner: 'partner/createPartner',
            updatePartner: 'partner/updatePartner'
        }),
        showAdminPortal(url) {
            AdminPortal.show();
            // eslint-disable-next-line no-return-assign
            setTimeout(() => (window.location.href = url), 200);
        },
        viewPartnerInfo(user) {
            this.$router.push({ name: 'partner-info', params: { partnerCode: user.partnerCode } });
        },
        async onPageChange({ page, queryString }) {
            await this.getAllPartners({
                page,
                pageSize: this.pageSize,
                queryString
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllPartners({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm
            });
        },
        async onAddPartner(partnerData) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                await this.createPartner(partnerData);

                this.$notify({
                    type: 'success',
                    text: 'Partner added successfully'
                });

                this.showAddPartnerModal = false;
            } catch (error) {
                console.error('Error adding partner:', error);
            }
        },
        async onUpdatePartner(partnerData) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                partnerData.id = this.partnerToUpdate.id;
                await this.updatePartner(partnerData);

                this.$notify({
                    type: 'success',
                    text: 'Partner updated successfully'
                });

                this.showUpdatePartnerModal = false;
                this.partnerToUpdate = null;
            } catch (error) {
                console.error('Error updating partner:', error);
            }
        }
    }
};
</script>
