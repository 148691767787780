<template>
    <loader :listen="['dashboard/getDashboardBasicMetrics']">
        <div v-can-access.role="'admin doctor partner partner-plus'" v-if="!noMetricsYet">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <h4 class="text__page-header mt-2">
                        <div class="d-flex align-items-center">
                            Dashboard
                            <div class="ml-2" v-can-access.role="'partner partner-plus'">
                                <span
                                    v-b-tooltip.hover
                                    class="badge badge-warning text-uppercase beta-badge"
                                    :title="'Partner portal is in a beta state. Please contact us if you encounter any issues.'">
                                    BETA <font-awesome-icon icon="fa fa-info-circle" />
                                </span>
                            </div>
                        </div>
                    </h4>
                    <div v-can-access.role="'partner partner-plus'" class="w-100 d-flex flex-column align-items-start">
                        <div v-if="user.partnerCode" class="font-weight-bold">
                            Internal Partner Code: <span class="text-success">{{ user.partnerCode }}</span>
                        </div>
                        <div v-if="user.metadata?.partnerCouponCode" class="font-weight-bold">
                            Linking Coupon Code: <span class="text-success">{{ user.metadata.partnerCouponCode }}</span>
                        </div>
                        <br />
                        <span
                            v-if="user.canDoDoctorReview"
                            class="badge badge-pill badge-info text-nowrap"
                            style="font-size: 0.85rem; padding: 0.2rem 0.4rem">
                            CAN DO DOCTOR REVIEW
                        </span>
                        <span
                            v-if="user.canDoPartnerApproval"
                            class="badge badge-pill badge-info text-nowrap"
                            style="font-size: 0.85rem; padding: 0.2rem 0.4rem">
                            CAN DO RESULT APPROVAL AFTER DOCTOR REVIEW
                        </span>
                        <span
                            v-if="!user.canDoDoctorReview && !user.canDoPartnerApproval"
                            class="badge badge-pill badge-info text-nowrap"
                            style="font-size: 0.85rem; padding: 0.2rem 0.4rem">
                            NO REVIEW/APPROVAL INCLUDED
                        </span>
                    </div>

                    <!-- Date Range Picker with Separate Start and End Inputs -->
                    <!-- Date Range Picker -->

                    <date-range :range="range" align="left" class="my-4" @change="onDateRangeChange" />

                    <!-- Gender Chart -->
                    <b-row v-can-access.role="'admin'" v-if="!noMetricsYet && dashboardBasicMetrics.patientReport?.averageAge?.count > 0">
                        <b-col>
                            <highcharts :options="genderChartOptions"></highcharts>
                        </b-col>
                    </b-row>
                    <div
                        v-can-access.role="'admin'"
                        v-if="dashboardBasicMetrics.patientReport && dashboardBasicMetrics.patientReport?.averageAge?.count <= 0"
                        class="card card-body text-center p-3 mx-2 my-2">
                        <div class="font-weight-bold">No gender metrics available</div>
                    </div>

                    <!-- Doctors Table -->
                    <b-row v-can-access.role="'partner-plus partner'" class="mt-5">
                        <b-col>
                            <h5 class="text-left mb-3">General Report</h5>
                            <b-table striped hover :items="partners" :fields="partnerTableFields" responsive="sm" />
                        </b-col>
                    </b-row>

                    <!-- Doctors Table -->
                    <b-row v-can-access.role="'admin doctor'" class="mt-5">
                        <b-col>
                            <h5 class="text-left mb-3">Doctor Results Report</h5>
                            <b-table striped hover :items="doctors" :fields="doctorTableFields" responsive="sm">
                                <template #cell(name)="row">
                                    <div class="d-flex align-items-center">
                                        <img width="30" :src="row.item.doctor.profilePictureUrl" class="rounded-circle mr-1" />
                                        <div>
                                            <b>{{ row.item.doctor.name }}</b>
                                            <small>
                                                <div v-if="row.item.doctor.specialty" class="text-muted">{{ row.item.doctor.specialty }}</div>
                                                <div v-if="row.item.doctor.email" class="text-muted font-weight-bold">
                                                    {{ row.item.doctor.email }}
                                                </div>
                                            </small>
                                        </div>
                                    </div>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </loader>
</template>

<script>
import { DASHBOARD } from '@/utils/constants';
import { mapGetters, mapActions } from 'vuex';
import DateRange from '@/components/global/date/date-range.vue';
import _ from 'lodash';

export default {
    components: {
        DateRange
    },
    data() {
        return {
            DASHBOARD,
            dateRange: {
                start: null,
                end: null
            },
            range: {
                value: 'all-time',
                type: 'custom'
            },
            partnerTableFields: [
                { key: 'users', label: 'Users' },
                { key: 'orders', label: 'Orders' },
                { key: 'results', label: 'Results' }
            ],
            doctorTableFields: [
                { key: 'name', label: 'Doctor Info' },
                { key: 'finishedResults', label: 'Finished Results' },
                { key: 'finishedResultsWithoutReview', label: 'Finished Without Review' }
            ]
        };
    },
    computed: {
        ...mapGetters({
            dashboardBasicMetrics: 'dashboard/dashboardBasicMetrics',
            user: 'user/currentUser'
        }),
        noMetricsYet() {
            return _.isEmpty(this.dashboardBasicMetrics) || _.isNil(this.dashboardBasicMetrics);
        },
        genderChartOptions() {
            const genderReport = this.dashboardBasicMetrics.patientReport?.genderReport;
            const totalUsers = this.dashboardBasicMetrics.patientReport?.averageAge.count;
            const averageAge = this.dashboardBasicMetrics.patientReport?.averageAge.averageAge;

            return {
                chart: {
                    type: 'pie'
                },
                title: {
                    text: 'Patient Gender Distribution'
                },
                subtitle: {
                    text: `Total Users: ${totalUsers} | Average Age: ${averageAge.toFixed(2)}`
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f}% <br/> Average Age: {point.averageAge}'
                        }
                    }
                },
                series: [
                    {
                        name: 'Users',
                        colorByPoint: true,
                        data: [
                            {
                                name: 'Male',
                                y: genderReport.M.count,
                                averageAge: genderReport.M.averageAge?.toFixed(2), // Passing average age to be used in the data label
                                color: '#77c3ec'
                            },
                            {
                                name: 'Female',
                                y: genderReport.F.count,
                                averageAge: genderReport.F.averageAge?.toFixed(2), // Passing average age to be used in the data label
                                color: '#ffb6c1' //
                            }
                        ]
                    }
                ]
            };
        },
        doctors() {
            return this.dashboardBasicMetrics?.doctorReport || [];
        },
        partners() {
            return this.dashboardBasicMetrics?.partnerReport ? [this.dashboardBasicMetrics?.partnerReport] : [];
        }
    },
    methods: {
        ...mapActions({
            getDashboardBasicMetrics: 'dashboard/getDashboardBasicMetrics'
        }),
        async onDateRangeChange(range) {
            this.range = range;

            await this.getDashboardBasicMetrics({ filter: this.range });
        }
    },
    async created() {
        await this.getDashboardBasicMetrics({ filter: this.range });
    }
};
</script>

<style scoped>
.remove-flex-wrap {
    flex-wrap: nowrap;
}
</style>
