class Client {
    parseResponse(response) {
        if (response.request && response.request.responseURL && response.request.responseURL.includes('logout')) {
            window.location.href = '/logout';
        }

        return _.get(response, 'data', undefined);
    }
}

export default new Client();
