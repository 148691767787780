<template>
    <div
        v-if="resultItem && (user.canDoDoctorReview || user.canDoPartnerApproval)"
        class="pt-5 px-2 fixed-bottom bg-light border-top border-secondary d-flex flex-column align-items-center justify-content-center">
        <!-- <h6 v-if="resultItem.finished" class="mb-3"><i class="fas fa-exclamation-triangle" /> {{ DOCTOR_ACTIONS.reviewClosureMessage }}</h6> -->
        <b-button pill variant="primary" class="action-toggle" :disabled="loading" @click.self="actionAriaToggle">
            {{ expand ? DOCTOR_ACTIONS.collapseActions : DOCTOR_ACTIONS.expandActions }}
        </b-button>
        <b-collapse id="collapse-4" v-model="expand" class="w-100">
            <div class="px-2">
                <label v-if="!resultItem.withoutReview || sendCommentAnyway" for="textarea_res">Comments for the result </label>
                <TextEditor
                    v-if="isTextEditable && user.canDoDoctorReview && (!resultItem.withoutReview || sendCommentAnyway)"
                    :initial-content="resultItem.comment"
                    :clear-content="clearComment"
                    @contentUpdated="(e) => (currentResult.comment = e)" />

                <TextEditorViewer
                    v-if="(!resultItem.withoutReview || sendCommentAnyway) && (!isTextEditable || !user.canDoDoctorReview)"
                    :content="resultItem.comment" />

                <div v-if="resultItem.withoutReview" class="mt-3 text-md text-success font-weight-bold">
                    This result does NOT need a review. {{ !resultItem.finished ? 'Please review & approve it' : '' }}
                </div>
                <div v-if="resultReviewedAndNowAwaitsPartnerApproval" class="mt-3 text-md text-success font-weight-bold">
                    Result reviewed. Awaiting partner approval.
                </div>
            </div>
            <div v-if="resultHasInvalidMarkers && !isResultFinished" class="d-flex flex-column align-items-center font-weight-bold mt-3">
                <label for="resultResolution">This result has invalid markers. Pick resolution:</label>
                <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                        id="btn-radios-2"
                        :disabled="!(isTextEditable && (user.canDoDoctorReview || user.canDoPartnerApproval))"
                        v-model="resultResolution"
                        :options="resolutionOptions"
                        :aria-describedby="ariaDescribedby"
                        size="md"
                        button-variant="outline-primary"
                        name="radio-btn-outline"
                        buttons></b-form-radio-group>
                </b-form-group>
            </div>
            <div v-if="resultItem.resolution" class="mt-3 text-lg">
                There is a resolution on this result, specifically: <b> {{ currentResultResolutionText }}</b>
            </div>
            <div class="my-2" v-if="resultItem.withoutReview && user.canDoDoctorReview">
                <b-form-group>
                    <b-form-checkbox v-model="sendCommentAnyway" name="sendCommentAnyway"> Send comment anyway </b-form-checkbox>
                </b-form-group>
            </div>
            <div class="d-flex flex-row mt-4 align-items-center justify-content-center actions-buttons">
                <div>
                    <loading-btn
                        v-if="(!resultItem.withoutReview || sendCommentAnyway) && user.canDoDoctorReview"
                        :listen="'result/updateCommentOnResult'"
                        class="btn btn-link mb-3"
                        :disabled="((user.canDoDoctorReview || user.canDoPartnerApproval) && currentResult.urgent) || !isTextEditable"
                        @click="updateComment()">
                        Update comment
                    </loading-btn>
                </div>
                <div>
                    <loading-btn
                        v-if="!resultReviewedAndNowAwaitsPartnerApproval"
                        :listen="'result/finishResult'"
                        class="btn btn-primary mb-3"
                        :disabled="
                            ((user.canDoDoctorReview || user.canDoPartnerApproval) && currentResult.urgent) ||
                            (!isTextEditable && !userCanNowDoApproval) ||
                            isResultFinished
                        "
                        @click="finishCurrentResult()">
                        {{
                            (resultItem.withoutReview && !sendCommentAnyway) || (!user.canDoDoctorReview && user.canDoPartnerApproval)
                                ? 'Approve'
                                : 'Finish'
                        }}
                    </loading-btn>
                </div>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { BUTTONS, DOCTOR_ACTIONS, STATUS_TYPES } from '@/utils/constants';
import TextEditor from './global/editor/text-editor';
import TextEditorViewer from './global/editor/text-editor-viewer';
import _ from 'lodash';

export default {
    components: { TextEditor, TextEditorViewer },
    props: {
        expanded: { type: Boolean, default: false }
    },
    data() {
        return {
            BUTTONS,
            DOCTOR_ACTIONS,
            STATUS_TYPES,
            currentResult: {},
            expand: false,
            clearComment: false,
            resultResolution: null,
            sendCommentAnyway: false,
            resolutionOptions: [
                { value: null, text: 'Nothing' },
                { value: 10, text: '10% Voucher' },
                { value: 20, text: '20% Voucher' },
                { value: 'retest', text: 'Free Retest' }
            ],
            editorOptions: {
                minHeight: '200px',
                language: 'en-US',
                useCommandShortcut: true,
                usageStatistics: true,
                hideModeSwitch: false,
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'image', 'link'],
                    ['scrollSync']
                ]
            }
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            result: 'result/currentResult',
            allUserResults: 'result/allUserResults'
        }),
        loading() {
            return !!this.$store.state.loading['result/getAllResults'];
        },
        resultItem() {
            const resultId = this.$route.params.resultId || this.$route.query.resultId || this.$route.params.id;
            const orderId = this.$route.params.orderId || this.$route.query.orderId;

            return this.result
                ? _.cloneDeep(this.result)
                : _.find(this.allUserResults[this.patientIdToUse], (res) => res && (res.id === resultId || res.orderId === orderId));
        },
        isTextEditable() {
            return this.resultItem.doctor && (this.resultItem.doctor.id === this.user.id || this.user.isAdmin);
        },
        userCanNowDoApproval() {
            return this.user.canDoPartnerApproval && this.resultItem.doctorHasReviewed && !this.resultItem.finished;
        },
        resultReviewedAndNowAwaitsPartnerApproval() {
            return !this.user.isPartnerPlus && this.resultItem.doctor && this.resultItem.doctorHasReviewed && !this.resultItem.finished;
        },
        isResultFinished() {
            return this.resultItem.finished;
        },
        patientIdToUse() {
            return this.$route.params.userId || this.$route.query.userId;
        },
        resultHasInvalidMarkers() {
            const result = this.resultItem;

            return result.hasInvalidMarkers;
        },
        currentResultResolutionText() {
            if (_.isString(this.resultItem.resolution)) {
                return this.resultItem.resolution === 'voucher' ? '20% Voucher' : this.resultItem.resolution;
            } else {
                return this.resultItem.resolution
                    ? this.resolutionOptions.find((option) => option.value === this.resultItem.resolution?.value || this.resultItem.resolution)?.text
                    : null;
            }
        }
    },
    watch: {
        expanded: {
            immediate: true,
            handler(val) {
                this.expand = val;
            }
        },
        result: {
            handler(val) {
                this.currentResult = _.cloneDeep(val);
            }
        }
    },
    async created() {
        if (!this.allUserResults[this.patientIdToUse]?.length) {
            await this.getAllResultsForAUser(this.patientIdToUse);
        }

        if (_.isString(this.resultItem.resolution)) {
            this.resultResolution = this.resultItem.resolution === 'voucher' ? 20 : this.resultItem.resolution;
        } else {
            this.resultResolution = this.resultItem.resolution?.value || null;
        }

        if (this.user.isPartner || this.user.isPartnerPlus) {
            this.resolutionOptions = this.resolutionOptions.filter((option) => option.value !== 'retest');
        }

        this.clearComment = false;
    },
    beforeDestroy() {
        this.$emit('toggleActions', false);
    },
    methods: {
        ...mapActions({
            getAllResultsForAUser: 'result/getAllResultsForAUser',
            updateCommentOnResult: 'result/updateCommentOnResult',
            finishResult: 'result/finishResult'
        }),
        actionAriaToggle() {
            this.expand = !this.expand;
            this.$emit('toggleActions', this.expand);
        },
        finishCurrentResult() {
            const resolutionOptionSelected = this.resolutionOptions.find((option) => option.value === this.resultResolution);

            this.$confirm({
                message: this.resultResolution
                    ? `Are you sure? You are about to finish the result and offer the the resolution: ${resolutionOptionSelected?.text}.`
                    : this.resultHasInvalidMarkers
                    ? 'Are you sure? You are about to finish with no resolution. The result has invalid markers.'
                    : 'Are you sure? You are about to finish the result.',
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.finishResult({
                                id: this.result.id || this.resultItem.id,
                                comment: _.isEmpty(this.currentResult.comment)
                                    ? this.$sanitize(this.resultItem.comment || null)
                                    : this.$sanitize(this.currentResult.comment || null),
                                resolution: {
                                    type: _.isNumber(this.resultResolution) ? 'voucher' : this.resultResolution,
                                    value: this.resultResolution
                                },
                                mustSendComment: this.sendCommentAnyway
                            });
                            this.$notify({
                                type: STATUS_TYPES.success,
                                text: DOCTOR_ACTIONS.resultClosedSuccessMessage
                            });

                            this.clearComment = true;
                            await this.$nextTick();
                            this.$router.push('/results-management');
                        } catch (e) {
                            this.$notify({
                                type: STATUS_TYPES.error,
                                text: _.get(e.response, 'data.message', null) || e.message || DOCTOR_ACTIONS.orderNotClosedErrorMessage
                            });
                        }
                    }
                }
            });
        },
        async updateComment() {
            try {
                await this.updateCommentOnResult({
                    resultId: this.result.id || this.resultItem.id,
                    comment: _.isEmpty(this.currentResult.comment)
                        ? this.$sanitize(this.resultItem.comment)
                        : this.$sanitize(this.currentResult.comment),
                    mustSendComment: this.sendCommentAnyway
                });
                this.$notify({
                    type: STATUS_TYPES.success,
                    text: DOCTOR_ACTIONS.resultUpdatedSuccessMessage
                });
            } catch (e) {
                this.$notify({
                    type: STATUS_TYPES.error,
                    text: _.get(e.response, 'data.message', null) || e.message || DOCTOR_ACTIONS.resultUpdateErrorMessage
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.action-toggle {
    position: absolute;
    top: -18px;
    max-width: 190px;
}

@media screen and (max-width: 778px) {
    .actions-buttons {
        flex-direction: column !important;
    }
}
</style>
