<template>
    <b-modal id="connect-partner-modal" v-model="modalVisible" @hidden="cancel">
        <template #modal-header="{ close }">
            <h5>{{ modalTitle }}</h5>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
        </template>

        <template #default>
            <div class="form-group d-flex flex-column align-items-center">
                <label for="partnerCode" class="col-12 col-form-label">Partner Code</label>
                <div class="col-12">
                    <input
                        id="partnerCode"
                        v-model="partnerCode"
                        name="partnerCode"
                        placeholder="Enter Partner Code"
                        class="form-control here"
                        type="text"
                        required
                        pattern="^[a-zA-Z0-9]+$"
                        title="Only alphanumeric characters allowed." />
                    <small v-if="!isValidCode && showErrors" class="text-danger">Please enter a valid alphanumeric partner code.</small>
                </div>
            </div>

            <!-- Disclaimer for order connection -->
            <div v-if="resource === 'orderId'" class="alert alert-warning mt-2">Connect the user to the partner before connecting the order.</div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-info" @click="cancel">Cancel</button>
            <loading-btn class="btn btn-primary" :disabled="disableConnectButton" :listen="'partner/createPartnerConnection'" @click="submit">
                Connect
            </loading-btn>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        resource: {
            type: String,
            required: true,
            validator: (value) => ['userId', 'orderId'].includes(value)
        },
        resourceId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modalVisible: false,
            partnerCode: '',
            showErrors: false
        };
    },
    computed: {
        modalTitle() {
            return this.resource === 'userId' ? 'Connect user to partner' : 'Connect order to the partner';
        },
        isValidCode() {
            const codePattern = /^[a-zA-Z0-9]+$/;
            return codePattern.test(this.partnerCode);
        },
        disableConnectButton() {
            return !this.isValidCode;
        }
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        submit() {
            this.showErrors = true;

            if (this.isValidCode) {
                // Emit the connectToPartner event with required data
                this.$emit('connectToPartner', {
                    partnerCode: this.partnerCode,
                    resource: this.resource,
                    resourceId: this.resourceId
                });
            }
        },
        cancel() {
            this.$emit('close');
            this.showErrors = false;
        }
    }
};
</script>
