<template>
    <b-modal id="modal-scoped" v-model="modalVisible" @hidden="cancel">
        <template #modal-header="{ close }">
            <h5>Add Partner</h5>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
        </template>

        <template #default>
            <!-- Name Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="name" class="col-12 col-form-label">Name</label>
                <div class="col-12">
                    <input id="name" v-model="user.name" name="name" placeholder="Full Name" class="form-control here" type="text" required />
                    <small v-if="nameError" class="text-danger">{{ nameError }}</small>
                </div>
            </div>

            <!-- Email Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="email" class="col-12 col-form-label">Email</label>
                <div class="col-12">
                    <input id="email" v-model="user.email" name="email" placeholder="Email" class="form-control here" required type="email" />
                    <small v-if="emailError" class="text-danger">{{ emailError }}</small>
                </div>
            </div>

            <!-- Role Selection with Descriptions -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="role" class="col-12 col-form-label">Role</label>
                <div class="col-12">
                    <b-form-group>
                        <b-form-radio v-model="user.role" name="role" :value="Roles.PARTNER_PLUS" @change="handleRoleChange(Roles.PARTNER_PLUS)">
                            Partner-Plus
                        </b-form-radio>
                        <small v-if="user.role === Roles.PARTNER_PLUS" class="text-muted"
                            >Partner-Plus can perform actions as you can select below.</small
                        >

                        <b-form-radio v-model="user.role" name="role" :value="Roles.PARTNER" @change="handleRoleChange(Roles.PARTNER)">
                            Partner
                        </b-form-radio>
                        <small v-if="user.role === Roles.PARTNER" class="text-muted">Partner gets only notifications and basic dashboard view.</small>
                    </b-form-group>
                    <small v-if="roleError" class="text-danger">{{ roleError }}</small>
                </div>
            </div>

            <!-- Coupon Code Input -->
            <div class="form-group d-flex flex-column align-items-center">
                <label for="partnerCouponCode" class="col-12 col-form-label">Coupon Code</label>
                <div class="col-12">
                    <input
                        id="partnerCouponCode"
                        v-model="user.partnerCouponCode"
                        name="partnerCouponCode"
                        placeholder="Coupon Code"
                        class="form-control here"
                        required />
                    <small v-if="couponCodeError" class="text-danger">{{ couponCodeError }}</small>
                </div>
            </div>

            <!-- Actions Selection (Shown Only for Partner-Plus) -->
            <div v-if="isPartnerPlusSelected" class="form-group d-flex flex-column align-items-center">
                <label for="actions" class="col-12 col-form-label">Actions</label>
                <div class="col-12">
                    <b-form-group>
                        <b-form-checkbox v-model="user.actions" name="actions" :value="UserActionPermissions.DOCTOR_REVIEW">
                            Review from Lola Doctor
                        </b-form-checkbox>
                        <b-form-checkbox v-model="user.actions" name="actions" :value="UserActionPermissions.PARTNER_REVIEW">
                            Partner Conducts Review and/or Approval
                        </b-form-checkbox>
                    </b-form-group>
                    <small class="text-info">{{ actionDescription }}</small>
                    <small v-if="actionsError" class="text-danger">{{ actionsError }}</small>
                </div>
            </div>

            <div class="form-group d-flex flex-column align-items-center">
                <label for="actions" class="col-12 col-form-label">Send Invitation Email</label>
                <div class="col-12">
                    <b-form-group>
                        <b-form-checkbox v-model="user.sendInvitationEmail" name="actions"> Send Invitation Email </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-info" @click="cancel">Cancel</button>
            <loading-btn class="btn btn-primary" :disabled="disableAddButton" :listen="'partner/createPartner'" @click="submit">
                Add Partner
            </loading-btn>
        </template>
    </b-modal>
</template>

<script>
import { Roles, UserActionPermissions, ACTION_DESCRIPTIONS } from '@/utils/constants';

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modalVisible: false,
            user: {
                name: null,
                email: null,
                role: 'partner-plus',
                partnerCouponCode: null,
                sendInvitationEmail: true,
                actions: []
            },
            Roles,
            UserActionPermissions,
            ACTION_DESCRIPTIONS
        };
    },
    computed: {
        isPartnerPlusSelected() {
            return this.user.role === Roles.PARTNER_PLUS;
        },
        actionDescription() {
            if (this.user.actions.includes(UserActionPermissions.DOCTOR_REVIEW) && this.user.actions.includes(UserActionPermissions.PARTNER_REVIEW)) {
                return ACTION_DESCRIPTIONS.BOTH;
            } else if (this.user.actions.includes(UserActionPermissions.DOCTOR_REVIEW)) {
                return ACTION_DESCRIPTIONS.DOCTOR_REVIEW;
            } else if (this.user.actions.includes(UserActionPermissions.PARTNER_REVIEW)) {
                return ACTION_DESCRIPTIONS.PARTNER_REVIEW;
            } else {
                return '';
            }
        },
        nameError() {
            return !this.user.name ? 'Name is required.' : '';
        },
        emailError() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !this.user.email ? 'Email is required.' : !emailPattern.test(this.user.email) ? 'Please enter a valid email address.' : '';
        },
        roleError() {
            return !this.user.role ? 'Please select a role.' : '';
        },
        couponCodeError() {
            const couponPattern = /^[a-zA-Z0-9]+$/;
            return !this.user.partnerCouponCode
                ? 'Coupon code is required.'
                : !couponPattern.test(this.user.partnerCouponCode)
                ? 'Coupon code should be alphanumeric only.'
                : '';
        },
        actionsError() {
            return this.isPartnerPlusSelected && this.user.actions.length === 0 ? 'At least one action is required for Partner-Plus.' : '';
        },
        disableAddButton() {
            return !!(this.nameError || this.emailError || this.roleError || this.couponCodeError || this.actionsError);
        }
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        handleRoleChange(role) {
            if (role === Roles.PARTNER) {
                this.user.actions = []; // Clear actions if switched to Partner
            }
        },
        submit() {
            if (!this.disableAddButton) {
                const payload = {
                    name: this.user.name,
                    email: this.user.email,
                    role: this.user.role, // Keep roles as an array with one element
                    partnerCouponCode: this.user.partnerCouponCode,
                    sendInvitationEmail: this.user.sendInvitationEmail,
                    actions: this.isPartnerPlusSelected ? this.user.actions : []
                };
                this.$emit('addPartner', payload);
            }
        },
        cancel() {
            this.$emit('close');
        }
    }
};
</script>
