<template>
    <b-modal id="tally-modal" v-model="modalVisible" @hidden="cancel" size="xl">
        <template #modal-header="{ close }">
            <h5>{{ title }}</h5>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times"></i>
            </button>
        </template>

        <template #default>
            <div class="d-flex justify-content-center">
                <iframe
                    :src="tallyFormUrl"
                    width="100%"
                    height="500px"
                    frameborder="0"
                    allow="fullscreen; encrypted-media"
                    style="border: none; overflow: hidden"></iframe>
            </div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-secondary" @click="cancel">Close</button>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Invite user to connect with partner'
        },
        tallyFormUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modalVisible: false
        };
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        cancel() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.modal {
    display: block;
}
</style>
