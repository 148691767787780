export function insertZendeskScript() {
    // Check if the environment is production
    if (process.env.VUE_APP_ENVIRONMENT === 'production') {
        // Check if the script is already added
        if (!document.getElementById('ze-snippet')) {
            const script = document.createElement('script');
            script.id = 'ze-snippet';
            script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5109e4d9-3cfc-4612-a816-b9bbdf3f87fd';
            script.async = true;
            document.head.appendChild(script);
        }
    }
}
