/* eslint-disable import/no-cycle */
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import api from '../api/user.api';
import UserModel from '../models/user.model';

export default {
    namespaced: true,
    state: {
        user: {},
        users: {},
        allPatients: {
            page: 1,
            page_size: 20,
            total: 0,
            queryString: null,
            data: {}
        },
        allPartners: {
            page: 1,
            page_size: 20,
            total: 0,
            queryString: null,
            data: {}
        }
    },
    getters: {
        currentUser(state, getters, rootState, rootGetters) {
            return new UserModel(state.user, rootState, rootGetters);
        },
        allPatients(state, getters, rootState, rootGetters) {
            return _.map(state.allPatients.data, (user) => new UserModel(user, rootState, rootGetters));
        },
        allUsers(state, getters, rootState, rootGetters) {
            return _.map(state.users, (user) => new UserModel(user, rootState, rootGetters));
        },
        allPartners(state, getters, rootState, rootGetters) {
            return _.map(state.allPartners.data, (user) => new UserModel(user, rootState, rootGetters));
        },
        patientPage(state) {
            return state.allPatients.page;
        },
        patientPageSize(state) {
            return state.allPatients.page_size;
        },
        patientTotal(state) {
            return state.allPatients.total;
        },
        patientQueryString(state) {
            return state.allPatients.queryString;
        },
        partnerPage(state) {
            return state.allPartners.page;
        },
        partnerPageSize(state) {
            return state.allPartners.page_size;
        },
        partnerTotal(state) {
            return state.allPartners.total;
        },
        partnerQueryString(state) {
            return state.allPartners.queryString;
        }
    },
    mutations: {
        setUser(state, user) {
            if (!state.user || (state.user && state.user.id != user.id)) {
                Sentry.setUser({
                    id: user.id,
                    email: user.email
                });
            }
            Vue.set(state, 'user', user);
        },
        setUserById(state, user) {
            Vue.set(state.users, user.id, user);
        },
        updateUser(state, user) {
            Vue.set(state.users, user.id, { ...state.users[user.id], ...user });
        },
        updateUserProfile(state, user) {
            Vue.set(state, 'user', { ...state.user, ...user });
        },
        updatePatient(state, patient) {
            Vue.set(state.allPatients, patient.id, { ...state.allPatients[patient.id], ...patient });
        },
        setAllPatients(state, allPatients) {
            Vue.set(state.allPatients, 'data', {});

            _.each(allPatients.data, (patient) => {
                Vue.set(state.allPatients.data, patient.id, patient);
                Vue.set(state.users, patient.id, patient);
            });

            Vue.set(state.allPatients, 'page', allPatients.page || 1);
            Vue.set(state.allPatients, 'page_size', allPatients.page_size || 20);
            Vue.set(state.allPatients, 'total', allPatients.total || 0);
            Vue.set(state.allPatients, 'queryString', allPatients.query_string || null);
        },
        setAllPartners(state, allPartners) {
            Vue.set(state.allPartners, 'data', {});

            _.each(allPartners.data, (partner) => {
                Vue.set(state.allPartners.data, partner.id, partner);
                Vue.set(state.users, partner.id, partner);
            });

            Vue.set(state.allPartners, 'page', allPartners.page || 1);
            Vue.set(state.allPartners, 'page_size', allPartners.page_size || 20);
            Vue.set(state.allPartners, 'total', allPartners.total || 0);
            Vue.set(state.allPartners, 'queryString', allPartners.query_string || null);
        },
        setPatientPagination(state, { page, pageSize, queryString }) {
            Vue.set(state.allPatients, 'page', page || 1);
            Vue.set(state.allPatients, 'page_size', pageSize || 20);
            Vue.set(state.allPatients, 'queryString', queryString || null);
        },
        setPartnerPagination(state, { page, pageSize, queryString }) {
            Vue.set(state.allPartners, 'page', page || 1);
            Vue.set(state.allPartners, 'page_size', pageSize || 20);
            Vue.set(state.allPartners, 'queryString', queryString || null);
        }
    },
    actions: {
        async getUser({ commit, state }, id) {
            commit('activateLoading', `user/getUser/${id}`, { root: true });
            try {
                let user;
                if (state.user.users) {
                    user = state.user.users[id];
                }
                if (user) {
                    return new UserModel(user);
                }

                const response = await api.getUser(id);
                commit('setUserById', response);
                return new UserModel(response);
            } finally {
                commit('deactivateLoading', `user/getUser/${id}`, { root: true });
            }
        },
        async updateUser({ commit }, user) {
            commit('activateLoading', 'user/updateUser', { root: true });
            try {
                const response = await api.updateUser(user);
                commit('updateUser', user);
                return response;
            } finally {
                commit('deactivateLoading', 'user/updateUser', { root: true });
            }
        },
        async updateProfile({ commit }, newUserDetails) {
            commit('activateLoading', 'user/updateProfile', { root: true });
            try {
                const response = await api.updateProfile(newUserDetails);
                commit('updateUserProfile', response);
                return response;
            } finally {
                commit('deactivateLoading', 'user/updateProfile', { root: true });
            }
        },
        async updateProfileHealthcareQuestionnaire({ commit }, payload) {
            commit('activateLoading', 'user/updateProfileHealthcareQuestionnaire', { root: true });
            try {
                const response = await api.updateProfileHealthcareQuestionnaire(payload);
                commit('updateUserProfile', response);
                return response;
            } finally {
                commit('deactivateLoading', 'user/updateProfileHealthcareQuestionnaire', { root: true });
            }
        },
        async signupPatient({ commit }, newUserDetails) {
            commit('activateLoading', 'user/signupPatient', { root: true });
            try {
                const response = await api.signupPatient(newUserDetails);
                commit('setUser', response);
                return response;
            } finally {
                commit('deactivateLoading', 'user/signupPatient', { root: true });
            }
        },
        async getAllPatients(
            { commit, state },
            {
                withLoading = true,
                page = state.allPatients.page,
                pageSize = state.allPatients.pace_size,
                queryString = state.allPatients.queryString
            } = {}
        ) {
            if (withLoading) commit('activateLoading', 'user/getAllPatients', { root: true });
            try {
                if (
                    page === state.allPatients.page &&
                    pageSize === state.allPatients.page_size &&
                    queryString &&
                    queryString === state.allPatients.queryString
                ) {
                    commit('deactivateLoading', 'user/getAllPatients', { root: true });
                    return;
                }

                commit('setPatientPagination', { page, pageSize, queryString });

                const response = await api.getAllPatients({
                    page,
                    page_size: pageSize,
                    query_string: queryString
                });

                commit('setAllPatients', response);
            } finally {
                if (withLoading) commit('deactivateLoading', 'user/getAllPatients', { root: true });
            }
        },
        async getAllPartners(
            { commit, state },
            {
                withLoading = true,
                page = state.allPartners.page,
                pageSize = state.allPartners.pace_size,
                queryString = state.allPartners.queryString
            } = {}
        ) {
            if (withLoading) commit('activateLoading', 'user/getAllPartners', { root: true });
            try {
                if (
                    page === state.allPartners.page &&
                    pageSize === state.allPartners.page_size &&
                    queryString &&
                    queryString === state.allPartners.queryString
                ) {
                    commit('deactivateLoading', 'user/getAllPartners', { root: true });
                    return;
                }

                commit('setPartnerPagination', { page, pageSize, queryString });

                const response = await api.getAllPartners({
                    page,
                    page_size: pageSize,
                    query_string: queryString
                });

                commit('setAllPartners', response);
            } finally {
                if (withLoading) commit('deactivateLoading', 'user/getAllPartners', { root: true });
            }
        },
        async getProfile({ commit }, withLoading = false) {
            if (withLoading) commit('activateLoading', 'user/getProfile', { root: true });
            try {
                const response = await api.getProfile();
                commit('setUser', response);
            } finally {
                if (withLoading) commit('deactivateLoading', 'user/getProfile', { root: true });
            }
        }
    }
};
