<template>
    <b-modal id="modal-scoped" v-model="modalVisible" @hidden="cancel" size="md">
        <template #modal-header="{ close }">
            <div class="d-flex flex-column align-items-center w-100">
                <h5 class="text-center">{{ title }}</h5>
                <span>{{ description }}</span>
            </div>
            <button size="sm" class="btn empty_background btn-link" @click="close()">
                <i class="fa fa-times" />
            </button>
            <hr />
        </template>

        <template #default>
            <div class="d-flex flex-column align-items-start">
                <div class="d-flex flex-column col-6 mb-3">
                    <label for="option" class="form-label">Download or Upload?</label>
                    <select v-model="option" class="form-select" id="option">
                        <option value="download">Download</option>
                        <option value="upload">Upload to dispatch provider</option>
                    </select>
                </div>

                <div class="d-flex flex-row col-12 align-items-center mt-3">
                    <span for="addQR" class="mr-2">Generate QR Code (for Lab Form)?</span>
                    <input v-model="addQR" type="checkbox" id="addQR" />
                </div>
            </div>
        </template>

        <template #modal-footer>
            <button class="btn btn-outline-info" @click="cancel">Close</button>
            <button class="btn btn-primary" @click="onGenerateKitDispatchCSV()" :disabled="generateKitDispatchCSVLoading">Generate</button>
        </template>
    </b-modal>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        orderIds: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: 'Generate Kit Dispatch CSV'
        },
        description: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            modalVisible: false,
            addQR: false,
            option: 'download'
        };
    },
    computed: {
        generateKitDispatchCSVLoading() {
            return this.$store.state.loading['order/generateKitDispatchCSV'];
        }
    },
    watch: {
        showModal: {
            immediate: true,
            handler(val) {
                this.modalVisible = val;
            }
        }
    },
    methods: {
        ...mapActions({
            generateKitDispatchCSV: 'order/generateKitDispatchCSV'
        }),
        cancel() {
            this.$emit('close');
        },
        async onGenerateKitDispatchCSV() {
            this.$confirm({
                message:
                    'Are you sure you want to generate the dispatch CSV?' +
                    (this.option === 'upload' ? 'This will upload the CSV to the dispatch provider, thus creating a dispatch request' : ''),
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            const response = await this.generateKitDispatchCSV({ orderIds: this.orderIds, option: this.option, addQR: this.addQR });

                            this.$notify({
                                type: 'success',
                                text: this.option === 'download' ? 'Dispatch CSV generated successfully' : 'Dispatch CSV uploaded successfully'
                            });

                            if (this.option === 'download') {
                                const blob = new Blob([response], { type: 'text/csv' });

                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                const dateIso = new Date().toISOString();

                                a.download = `YPL_KIT_ORDERS_${dateIso}.csv`;
                                a.click();
                                window.URL.revokeObjectURL(url);
                            }

                            this.$emit('close');
                        } catch (e) {
                            console.error('Error generating kit dispatch CSV:', e);
                            this.$emit('close');
                        }
                    }
                }
            });
        }
    }
};
</script>
