<template>
    <loader :listen="['user/getAllPatients', 'order/getAllUserOrders', 'result/getAllResults', 'auth']">
        <div class="home" />
    </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import { Routes } from '@/utils/constants';
import { formatRoute } from '../utils/utils';

export default {
    data() {
        return {
            ...this.mapAuthState()
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        })
    },
    mounted() {
        this.redirectBasedOnRole();
    },
    methods: {
        redirectBasedOnRole() {
            if (this.user.isDoctor) {
                this.$router.push(formatRoute(Routes.RESULTS_MANAGEMENT));
            }

            if (this.user.hasAccessToTheApp) {
                this.$router.push(formatRoute(Routes.DASHBOARD));
            }
        }
    }
};
</script>

<style lang="scss">
@import '../styles/home.scss';
</style>
