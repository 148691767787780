<template>
    <div class="h-100">
        <div class="d-flex align-items-center w-100 flex-wrap text-left" :class="alignCenter ? 'justify-content-center' : ''">
            <div class="d-flex flex-row flex-md-column col-6 col-md-4 pl-0 pr-0">
                <div class="d-flex align-items-center">
                    <img
                        v-b-tooltip.hover.top
                        :src="require(`@/assets/svg/marker/marker-red.svg`)"
                        height="27"
                        title="Deficient markers"
                        class="mr-2" />
                    <div>
                        <label class="mr-1 secondary-label">Deficient markers</label>
                        <span class="analysis-title text-left">{{ categories.bad }}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row flex-md-column col-6 col-md-4 pl-0 pr-0">
                <div class="d-flex align-items-center">
                    <img v-b-tooltip.hover.top :src="require(`@/assets/svg/marker/marker-green.svg`)" height="27" title="Good markers" class="mr-2" />
                    <div>
                        <label class="mr-1 secondary-label">Good markers</label>
                        <span class="analysis-title text-left">{{ categories.good }}</span>
                    </div>
                </div>
            </div>
            <div
                class="d-flex flex-row flex-md-column col-12 col-md-4 pl-0 pr-0 invalid-margin"
                :class="centerOnMobile ? 'justify-content-center' : ''">
                <div class="d-flex align-items-center">
                    <img
                        v-b-tooltip.hover.top
                        :src="require(`@/assets/svg/marker/marker-grey.svg`)"
                        height="27"
                        title="Invalid markers"
                        class="mr-2" />
                    <div>
                        <label class="mr-1 secondary-label">Invalid markers</label>
                        <span class="analysis-title text-left"> {{ categories.uncategorized }}</span>
                    </div>
                </div>
            </div>
            <div v-if="resultToUse && showResultButton" class="comment-result mt-3" :class="showComment ? 'flex-column' : ''">
                <div v-if="resultToUse?.comment" class="w-100">
                    <div class="comment-description">
                        <div class="my-1 d-flex align-items-center">
                            <img v-if="resultToUse.doctor" width="30" :src="resultToUse.doctor.profilePictureUrl" class="rounded-circle mr-1" />
                            <span> On {{ resultToUse.dateUpdated }}, Dr. {{ resultToUse.doctor.name }} left a comment </span>
                        </div>

                        <button v-if="resultToUse?.comment" class="btn btn-link small" @click="showComment = !showComment">
                            {{ showComment ? 'Hide comment' : 'See comment' }}
                        </button>
                    </div>
                    <TextEditorViewer v-if="showComment" :content="resultToUse.comment" />
                </div>

                <div class="d-flex flex-row flex-md-column align-self-end align-self-md-auto col-12 col-md-3">
                    <button v-if="showResultButton" class="btn btn-primary" @click="goToResult()">See result</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ABNORMAL_FLAGS_TAGGED, ABNORMAL_FLAGS, greenColor } from '@/utils/constants';
import TextEditorViewer from './global/editor/text-editor-viewer';

export default {
    components: { TextEditorViewer },
    props: {
        order: {
            type: Object,
            default: null
        },
        result: {
            type: Object,
            default: null
        },
        category: {
            type: String,
            default: null
        },
        showResultButton: {
            type: Boolean,
            default: false
        },
        filteredMarkers: {
            type: Array,
            default: null
        },
        alignCenter: {
            type: Boolean,
            default: false
        },
        centerOnMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ABNORMAL_FLAGS_TAGGED,
            ABNORMAL_FLAGS,
            greenColor,
            showComment: false
        };
    },
    computed: {
        categories() {
            const categories = {
                bad: 0,
                good: 0,
                uncategorized: 0
            };

            if (!this.resultToUse) return categories;

            const resultSummary = this.resultToUse?.resultSummary || this.resultToUse;

            const filteredMarkers = this.filteredMarkers
                ? [...this.filteredMarkers[0], ...this.filteredMarkers[1]].filter((x) => x).map((x) => x.testName)
                : null;
            let finalMarkers = this.category
                ? resultSummary[this.category] || _.filter(resultSummary, (marker) => marker.testCategory === this.category)
                : resultSummary;
            finalMarkers = filteredMarkers ? _.filter(finalMarkers, (m) => filteredMarkers.includes(m.testName)) : finalMarkers;

            finalMarkers.forEach((marker) => {
                if (
                    !marker.invalid &&
                    marker.abnormalFlags &&
                    ABNORMAL_FLAGS_TAGGED[marker.abnormalFlags.flag] === 'bad' &&
                    !marker.abnormalFlags.flag !== 'NAF'
                ) {
                    categories.bad++;
                } else if (marker.invalid && marker.abnormalFlags.flag !== 'NAF') {
                    categories.uncategorized++;
                } else {
                    categories.good++;
                }
            });

            const totalMarkers = categories.bad + categories.uncategorized + categories.good;

            const percentages = {
                bad: `${((categories.bad * 100) / totalMarkers).toFixed(2)}%`,
                good: `${((categories.good * 100) / totalMarkers).toFixed(2)}%`,
                uncategorized: `${((categories.uncategorized * 100) / totalMarkers).toFixed(2)}%`
            };

            return percentages;
        },
        resultToUse() {
            return this.result || this.order?.result;
        }
    },
    methods: {
        goToResult() {
            this.$router.push({ name: 'result', params: { id: this.result.id || this.order.id }, query: { userId: this.result.userId } });
        }
    }
};
</script>

<style lang="scss">
.comment-result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.comment-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 778px) {
    .comment-description {
        display: flex;
        flex-direction: column;
    }

    .comment-result {
        flex-direction: column;
    }

    .invalid-margin {
        @media screen and (max-width: 763px) {
            margin-top: 1rem !important;
        }
    }
}
</style>
