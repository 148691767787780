/* eslint-disable no-unused-vars */
import { constructQueryParams } from '@/utils/utils';
import api from './api';

export default {
    async getMetrics(filter) {
        const response = await api.get(`dashboard/metrics?${filter ? `filter=${JSON.stringify(filter)}` : ''}`);

        return response;
    }
};
