/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import { getDisplayDate, getFirstAndLastNameFromFullName } from '@/utils/utils';
import { getInstance } from '@/utils/auth.instance';
import { Roles, SubscriptionEntitlementName, UserActionPermissions } from '@/utils/constants';
import Model from './model';

const defaultPhoto = require('../../assets/img/profile-picture.jpg');

export default class UserModel extends Model {
    get loggedIn() {
        return !!this.id || !!this.email;
    }

    get bmi() {
        return this.attributes.profileData?.bmi || this.attributes.bmi || null;
    }

    get age() {
        if (!this.dob) return null;

        if (this.attributes.profileData?.age || this.attributes.age) {
            return this.attributes.profileData?.age || this.attributes.age;
        }

        const toUse = moment(this.dob, 'MMMM DD YYYY');

        return moment().diff(toUse, 'years');
    }

    get pregnancyOutcomeStatus() {
        return {
            status: this.attributes.pregnancyOutcomeStatus?.status,
            lastUpdatedDate: new Date(this.attributes.pregnancyOutcomeStatus?.lastUpdatedDate)
        };
    }

    get isPatient() {
        return this.attributes.isPatient;
    }

    get name() {
        return this.attributes.profileData?.name || this.attributes.name;
    }

    get concatenatedName() {
        const fullNameJSON = getFirstAndLastNameFromFullName(this.name);

        return `${fullNameJSON.firstName}_${fullNameJSON.lastName}`?.replace(/ /g, '_');
    }

    get email() {
        return this.attributes.profileData?.email || this.attributes.email;
    }

    get weight() {
        return this.attributes.profileData?.weight || this.attributes.weight;
    }

    get height() {
        return this.attributes.profileData?.height || this.attributes.height;
    }

    get profilePictureUrl() {
        return this.attributes.profileData?.profilePictureUrl || this.attributes.profilePictureUrl || `${window.location.origin}${defaultPhoto}`;
    }

    get gender() {
        return this.attributes.profileData?.gender || this.attributes.gender;
    }

    get unitSystem() {
        return this.attributes.profileData?.unitSystem || this.attributes.unitSystem;
    }

    get lastLoginDate() {
        return this.attributes.profileData?.lastLoginDate || this.attributes.lastLoginDate;
    }

    get isDemo() {
        return this.attributes.demo;
    }

    get useDoctorLanguage() {
        return this.isDoctor && !this.isAdmin;
    }

    get isAdmin() {
        return this.attributes.isAdmin;
    }

    get isDoctor() {
        return this.attributes.isDoctor;
    }

    get isPartner() {
        return this.attributes.isPartner;
    }

    get isPartnerPlus() {
        return this.attributes.isPartnerPlus;
    }

    get isSuperAdmin() {
        return this.attributes.isSuperAdmin;
    }

    hasRole(role) {
        return this.attributes.roles.some((r) => (r.key ?? r) === role);
    }

    get dob() {
        const dob = this.attributes.profileData?.dob || this.attributes.dob;
        return getDisplayDate(dob, 'MMM DD YYYY');
    }

    get accessGranted() {
        return this.attributes.accessGranted;
    }

    get isProspect() {
        return this.attributes.isProspect;
    }

    get readOnly() {
        return this.attributes.readOnly;
    }

    get wantDoctorReview() {
        if (!this.isPartnerPlus) return false;

        let metadata = this.attributes.metadata || {};

        // Parse metadata if it's a string
        metadata = _.isString(metadata) ? JSON.parse(metadata) : metadata;

        return metadata?.actions?.includes(UserActionPermissions.DOCTOR_REVIEW) || false;
    }

    get wantOwnReview() {
        if (!this.isPartnerPlus) return this.isDoctor; // Doctors can always review their own results

        let metadata = this.attributes.metadata || {};

        // Parse metadata if it's a string
        metadata = _.isString(metadata) ? JSON.parse(metadata) : metadata;

        return metadata?.actions?.includes(UserActionPermissions.PARTNER_REVIEW) || false;
    }

    get willHaveToApproveThemselves() {
        return this.wantDoctorReview && this.wantOwnReview;
    }

    hasAnyPermission(permissions, gateType = 'or') {
        let metadata = this.attributes.metadata || {};

        // Parse metadata if it's a string
        metadata = _.isString(metadata) ? JSON.parse(metadata) : metadata;

        // Gather all permissions from metadata
        const actionPermissions = metadata?.actions || [];
        const permissionsToCheck = metadata?.permissions || [];
        const allPermissions = _.uniq([...permissionsToCheck, ...actionPermissions].filter(Boolean));

        // Split the incoming permissions string into an array
        const permissionArray = permissions.split(' ');

        // Use Lodash's `some` or `every` based on gateType to check permissions directly against allPermissions
        const checkMethod = gateType === 'and' ? _.every : _.some;

        return checkMethod(permissionArray, (item) => allPermissions.includes(item)) || false;
    }

    hasEntitlements(entitlements, gateType = 'or') {
        const entitlementsInfo = this.attributes.entitlements;
        if (!entitlementsInfo) return false;

        const entitlementsArray = entitlements.split(' ');
        const checkMethod = gateType === 'and' ? _.every : _.some;

        return checkMethod(entitlementsArray, (item) => entitlementsInfo[SubscriptionEntitlementName[item] || item]) || false;
    }

    canAccess(level, gateType = 'or') {
        const roles = this.attributes.roles || [{ key: Roles.VIEW }];
        const levelArray = level.split(' ');
        const checkMethod = gateType === 'and' ? _.every : _.some;

        return checkMethod(levelArray, (item) => roles.some((r) => (r.key ?? r) === (Roles[item.toUpperCase()] || item)));
    }

    get canDoDoctorReview() {
        return this.isAdmin || this.isDoctor || (this.isPartnerPlus && this.wantOwnReview && !this.wantDoctorReview);
    }

    get canDoPartnerApproval() {
        return this.isAdmin || (this.isPartnerPlus && this.wantDoctorReview && this.wantOwnReview);
    }

    get tenant() {
        const tenantInfo = getInstance()?.tenantsState?.tenants[0] || this.attributes.currentTenant;
        if (!tenantInfo) return false;

        let metadata = _.isString(tenantInfo.metadata) ? JSON.parse(tenantInfo.metadata) : tenantInfo.metadata;
        metadata = metadata ? { ...metadata, ...this.attributes.currentTenant.metadata } : this.attributes.currentTenant?.metadata;

        return { ...tenantInfo, ...this.attributes.currentTenant, metadata };
    }

    get metadata() {
        return this.attributes.metadata
            ? _.isString(this.attributes.metadata)
                ? JSON.parse(this.attributes.metadata)
                : this.attributes.metadata
            : this.attributes.metadata;
    }

    get hasAccessToTheApp() {
        return this.isDoctor || this.isAdmin || this.isPartner || this.isPartnerPlus || this.isSuperAdmin;
    }

    toJSON() {
        const jsonObj = super.toJSON();
        delete jsonObj.age;
        delete jsonObj.isRegistered;
        delete jsonObj.accessGranted;
        delete jsonObj.askForSubscription;
        delete jsonObj.healthcareQuestionnaire;

        return {
            ...jsonObj,
            profileData: this.attributes.profileData,
            isPatient: this.attributes.isPatient
        };
    }

    toProfileJSON() {
        const jsonObj = super.toJSON();

        const basicInfo = {
            name: jsonObj.profileData?.name || jsonObj.name,
            email: jsonObj?.email,
            profilePictureUrl: this.profilePictureUrl == defaultPhoto ? this.attributes.profileData?.profilePictureUrl : this.profilePictureUrl,
            gender: jsonObj.profileData?.gender,
            unitSystem: jsonObj.profileData?.unitSystem || jsonObj.unitSystem,
            lastLoginDate: jsonObj?.lastLoginDate
        };

        if (this.isDoctor || this.isPartnerPlus) {
            return {
                ...basicInfo,
                specialty: jsonObj.profileData.specialty
            };
        }

        if (this.isPatient) {
            return {
                ...basicInfo,
                weight: jsonObj.profileData?.weight || jsonObj.weight,
                height: jsonObj.profileData?.height || jsonObj.height,
                defaultFilters: jsonObj.defaultFilters,
                dob: jsonObj.profileData?.dob,
                profileData: jsonObj.profileData,
                age: this.age || null,
                bmi: this.bmi || null
            };
        }

        return basicInfo;
    }
}
