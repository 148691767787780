<template>
    <ValidationObserver v-slot="{ invalid }" ref="observer">
        <div class="d-flex flex-column my-3">
            <div class="d-flex flex-column align-items-center w-100">
                <div class="d-flex w-100 align-items-center justify-content-end pr-2">
                    <button v-if="!user.readOnly" icon="fa fa-arrow" class="btn btn-link w-auto" @click.prevent="enableEdit = !enableEdit">
                        {{ enableEdit ? 'Cancel' : 'Edit' }}
                        <i class="fa fa-edit fa-xs pl-1" />
                    </button>
                </div>
                <p v-if="enableEdit" :class="enableEdit ? 'mb-3 w-100 w-md-50 px-3 px-md-0' : 'mb-1'">
                    <span class="d-flex flex-row form-group align-items-center">
                        <span class="mr-1">
                            <img :src="userProfile.profilePictureUrl" height="40" />
                        </span>
                        <ValidationProvider v-slot="{ errors }" name="Last Name" rules="required" class="w-100">
                            <input
                                id="photo"
                                v-model="userProfile.profilePictureUrl"
                                name="Photo"
                                placeholder="URL to your photo"
                                class="form-control"
                                required="required"
                                type="text" />
                            <div class="w-100 text-left text-danger">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </span>
                </p>
                <div v-else style="width: 150px; padding: 10px">
                    <img :src="user.profilePictureUrl" alt="Profile Picture" class="img-fluid img-thumbnail" style="width: 150px; z-index: 1" />
                </div>
                <div class="d-flex flex-column align-items-center">
                    <h5 class="m-0 p-0">
                        <p class="mb-1" :class="enableEdit ? 'mb-3' : 'mb-1'">
                            <ValidationProvider v-if="enableEdit" v-slot="{ errors }" name="Name" rules="required">
                                <input
                                    id="name"
                                    v-model="userProfile.name"
                                    name="name"
                                    placeholder="Name"
                                    class="form-control"
                                    type="text"
                                    required />
                                <div class="w-100 text-left text-danger">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                            <span v-else>{{ user.name }}</span>
                        </p>
                    </h5>
                    <p v-if="user.isDoctor || user.isPartnerPlus" class="mb-1">
                        <ValidationProvider v-if="enableEdit" v-slot="{ errors }" name="Speciality">
                            <input
                                id="specialty"
                                v-model="userProfile.specialty"
                                name="speciality"
                                placeholder="Your speciality"
                                class="form-control"
                                type="text" />
                            <div class="w-100 text-left text-danger">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                        <span v-else><span class="font-weight-bold"> Speciality: </span>{{ user.specialty }}</span>
                    </p>
                    <small v-if="!enableEdit" class="text-muted">{{ user.email }}</small>
                    <span v-if="user.bmi && !enableEdit" class="my-1"><span class="text-muted">BMI Score</span>: {{ user.bmi?.toFixed(2) }}</span>
                    <!-- <div v-if="user.isPatient" class="d-flex justify-content-center align-items-center text-center basic-info mt-2">
                            <div class="d-flex">
                                <div v-if="user.gender" class="pr-2">
                                    <select v-if="enableEdit" id="select" v-model="userProfile.gender" name="select" class="custom-select">
                                        <option value="M">M</option>
                                        <option value="F">F</option>
                                    </select>
                                    <p v-else class="mb-1 h6">
                                        {{ user.gender }}
                                    </p>
                                    <p class="small text-muted mb-0">Birth Gender</p>
                                </div>
                                <div v-if="user.dob" class="px-2">
                                    <date-picker v-if="enableEdit" v-model="userProfile.dob" :max-date="maxDate">
                                        <template #default="{ inputValue, inputEvents }">
                                            <input class="px-3 py-1 border rounded" :value="inputValue" style="width: 7rem" v-on="inputEvents" />
                                        </template>
                                    </date-picker>
                                    <p v-else class="mb-1 h6">
                                        {{ user.dob }}
                                    </p>
                                    <p class="small text-muted mb-0">DOB</p>
                                </div>
                            </div>
                            <div class="d-flex py-1">
                                <div class="px-2">
                                    <div style="display: flex">
                                        <div v-show="enableEdit && height.heightUnitSecondaryValue" class="mb-3 pr-2 w-50">
                                            <ValidationProvider v-slot="{ errors }" name="Height" rules="required">
                                                <div class="input-group">
                                                    <input
                                                        id="height"
                                                        v-model="height.heightUnitSecondaryValue"
                                                        class="form-control"
                                                        type="number"
                                                        :placeholder="UNIT_SYSTEMS.imperial.heightUnitSecondary"
                                                    />
                                                    <div class="input-group-append">
                                                        <span id="kg" class="input-group-text">{{ UNIT_SYSTEMS.imperial.heightUnitSecondary }}</span>
                                                    </div>
                                                </div>
                                                <div class="w-100 text-left text-danger">
                                                    {{ errors[0] }}
                                                </div>
                                            </ValidationProvider>
                                        </div>

                                        <div class="" :class="unitSystem === UNIT_SYSTEMS.imperial.id ? '' : ''">
                                            <ValidationProvider v-if="enableEdit" v-slot="{ errors }" name="Height" rules="required">
                                                <div class="input-group">
                                                    <input
                                                        id="height"
                                                        v-model="height.heightUnitValue"
                                                        class="form-control"
                                                        type="number"
                                                        placeholder="Height"
                                                    />
                                                    <div class="input-group-append">
                                                        <span id="heightUnitMain" class="input-group-text">{{ heightUnit }}</span>
                                                    </div>
                                                </div>
                                                <div class="w-100 text-left text-danger">
                                                    {{ errors[0] }}
                                                </div>
                                            </ValidationProvider>
                                            <p v-else-if="height.heightUnitValue" class="mb-1 h6">
                                                <span v-if="height.heightUnitSecondaryValue">
                                                    {{ height.heightUnitSecondaryValue }}
                                                    {{ UNIT_SYSTEMS.imperial.heightUnitSecondary }}
                                                </span>
                                                {{ height.heightUnitValue }} {{ heightUnit }}
                                            </p>
                                            <p v-else class="mb-1 h6 text-muted">Not Set</p>
                                        </div>
                                    </div>
                                    <p class="small text-muted mb-0">Height</p>
                                </div>
                                <div class="px-2">
                                    <ValidationProvider v-if="enableEdit" v-slot="{ errors }" name="Weight" rules="required">
                                        <div class="input-group">
                                            <input
                                                id="weight"
                                                v-model="weight.weightUnitValue"
                                                class="form-control"
                                                type="number"
                                                placeholder="Weight"
                                            />
                                            <div class="input-group-append">
                                                <span id="kg" class="input-group-text">{{ weightUnit }}</span>
                                            </div>
                                        </div>
                                        <div class="w-100 text-left text-danger">
                                            {{ errors[0] }}
                                        </div>
                                    </ValidationProvider>
                                    <p v-else-if="weight.weightUnitValue" class="mb-1 h6">{{ weight.weightUnitValue }} {{ weightUnit }}</p>
                                    <p v-else class="mb-1 h6 text-muted">Not Set</p>
                                    <p class="small text-muted mb-0">Weight</p>
                                </div>
                            </div>
                        </div> -->
                    <div v-if="enableEdit" class="w-100 d-flex align-items-center justify-content-center flex-column mt-2">
                        <div class="mb-3 d-flex align-items-center justify-content-center flex-column">
                            <loading-btn
                                :listen="'user/updateProfile'"
                                class="btn btn-primary"
                                :disabled="disableUpdate || invalid"
                                @click.prevent="updateUserProfile()">
                                Update
                            </loading-btn>
                        </div>
                        <div>
                            <button class="btn btn-primary" @click.prevent="enableEdit = !enableEdit">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-4 text-black">
            <div class="mb-3 text-left">
                <p class="lead fw-normal mb-1 d-flex flex-column flex-md-row align-items-center align-items-md-start">Account Settings</p>
                <div class="p-4" style="background-color: #f8f9fa">
                    <div v-if="onesignalLoaded">
                        <div v-if="browserSupportsNotifications" class="d-flex align-items-center mb-3">
                            <b-form-checkbox v-model="notificationState" switch :disabled="isDisabled" @change="changeNotificationState" />
                            <div>Notifications</div>
                        </div>
                    </div>
                    <div>
                        <span>Unit System: </span>
                        <div class="mt-1">
                            <toggle-switch
                                key="unitSystem"
                                :align-apply-button="'right'"
                                :main-key="'unitSystem'"
                                :is-disabled="isDisabled"
                                :label="''"
                                :listen-event="'user/updateProfile'"
                                :selected="unitSystem"
                                :options="[UNIT_SYSTEMS.imperial, UNIT_SYSTEMS.metric]"
                                :track-by="'id'"
                                :label-key="'name'"
                                @change="updateUnitSystem"
                                @apply="updateUserProfile" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import LoadingBtn from '@/components/global/buttons/loading-btn';
import { UNIT_SYSTEMS } from '@/utils/constants';
import { cmToInch, feetAndInchToInchOnly, inchToCm, inchToFeet, inchToRemainingInch, kgToLbs, lbsToKg } from '@/utils/utils';
import ToggleSwitch from './global/switch/toggle-switch.vue';

export default {
    components: {
        LoadingBtn,
        ToggleSwitch
    },
    data() {
        const maxDate = new Date();

        return {
            UNIT_SYSTEMS,
            userProfile: {},
            disableUpdate: true,
            enableEdit: false,
            notificationState: null,
            browserSupportsNotifications: false,
            mainBrowserNotifState: false,
            onesignalLoaded: false,
            maxDate: new Date(maxDate.setFullYear(maxDate.getFullYear() - 18)),
            height: {
                heightUnitValue: null,
                heightUnitSecondaryValue: null
            },
            weight: {
                weightUnitValue: null
            },
            unitSystem: 'metric'
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        weightUnit() {
            if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.weightUnit;
            }

            return UNIT_SYSTEMS.metric.weightUnit;
        },
        heightUnit() {
            if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.heightUnitMain;
            }

            return UNIT_SYSTEMS.metric.heightUnitMain;
        },
        isDisabled() {
            return this.$store.state.loading['user/updateProfile'];
        }
    },
    watch: {
        userProfile: {
            deep: true,
            handler(val) {
                const user = { ...this.user.toProfileJSON(), ...val };
                if (val.dob) {
                    user.dob = moment(val.dob).format('DD/MM/YYYY');
                }

                this.disableUpdate = _.isEqual(user, this.user.toProfileJSON());
            }
        }
    },
    async created() {
        this.userProfile = _.cloneDeep(this.user.toProfileJSON());

        if (this.userProfile.unitSystem) {
            this.unitSystem = this.userProfile.unitSystem;
        } else {
            this.unitSystem = UNIT_SYSTEMS.metric.id;
        }

        // if (this.user.isPatient) {
        //     const heightCm = this.userProfile.height;
        //     const weightKg = this.userProfile.weight;

        //     if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
        //         const heightInch = cmToInch(heightCm);
        //         const remainingInch = inchToRemainingInch(Number(heightInch));
        //         const feet = inchToFeet(Number(heightInch));
        //         this.height.heightUnitValue = remainingInch;
        //         this.height.heightUnitSecondaryValue = feet;
        //         this.weight.weightUnitValue = kgToLbs(weightKg);
        //     } else {
        //         this.height.heightUnitValue = heightCm;
        //         this.weight.weightUnitValue = weightKg;
        //     }

        //     this.pregnancyOutcomeStatus = this.user.pregnancyOutcomeStatus;
        // }

        if (this.user.dob) {
            this.userProfile.dob = new Date(moment(this.user.dob).valueOf());
        }

        // if (this.user.isPatient && this.noResults) {
        //     await this.getAllResultsForAUser(this.user.id);
        // }

        this.browserSupportsNotifications = await this.$OneSignal.Notifications.isPushSupported();

        if (this.browserSupportsNotifications) {
            const onesignalMainBrowserNotifState = await this.$OneSignal.Notifications.permission;

            if (onesignalMainBrowserNotifState) {
                const onesignalNotifState = await this.$OneSignal.User.PushSubscription.optedIn;
                this.notificationState = onesignalNotifState;
                this.mainBrowserNotifState = true;
            } else {
                this.mainBrowserNotifState = false;
                this.notificationState = false;
            }

            this.$OneSignal.Notifications.addEventListener('permissionChange', (permission) => {
                if (permission) {
                    this.mainBrowserNotifState = true;
                    this.notificationState = true;
                } else {
                    this.mainBrowserNotifState = false;
                    this.notificationState = false;
                }
            });
        }

        this.onesignalLoaded = true;
    },
    methods: {
        ...mapActions({
            updateProfile: 'user/updateProfile',
            getStripeDashboardInstance: 'stripe/getStripeDashboardInstance',
            getProfile: 'user/getProfile',
            getLolaScores: 'user/getLolaScores',
            getAllResultsForAUser: 'result/getAllResultsForAUser',
            getQuiz: 'quiz/getById'
        }),
        updateUnitSystem(system) {
            this.unitSystem = system;
            if (system === UNIT_SYSTEMS.imperial.id) {
                const inch = cmToInch(this.height.heightUnitValue);
                const remainingInch = inchToRemainingInch(Number(inch));
                const feet = inchToFeet(Number(inch));
                this.height.heightUnitValue = remainingInch;
                this.height.heightUnitSecondaryValue = feet;
                this.weight.weightUnitValue = kgToLbs(Number(this.weight.weightUnitValue));
            } else {
                const inchOnly = feetAndInchToInchOnly(Number(this.height.heightUnitSecondaryValue), Number(this.height.heightUnitValue));
                this.height.heightUnitValue = inchToCm(Number(inchOnly));
                this.height.heightUnitSecondaryValue = '';
                this.weight.weightUnitValue = lbsToKg(this.weight.weightUnitValue);
            }
        },
        async updateUserProfile() {
            const payload = _.cloneDeep(this.userProfile);

            payload.email = this.$sanitize(payload.email.toLowerCase().trim());
            payload.name = this.$sanitize(payload.name);
            payload.weight = this.$sanitize(payload.weight);
            payload.height = this.$sanitize(payload.height);
            payload.unitSystem = this.$sanitize(this.unitSystem);
            payload.pregnancyOutcomeStatus = this.pregnancyOutcomeStatus;

            if (payload.unitSystem === UNIT_SYSTEMS.imperial.id) {
                const inchOnly = feetAndInchToInchOnly(Number(this.height.heightUnitSecondaryValue), Number(this.height.heightUnitValue));
                const cmOnly = inchToCm(inchOnly);
                const kg = lbsToKg(this.weight.weightUnitValue);

                if (cmOnly !== payload.height) {
                    payload.height = this.$sanitize(cmOnly);
                }

                if (kg !== payload.kg) {
                    payload.weight = this.$sanitize(kg);
                }
            } else if (payload.unitSystem === UNIT_SYSTEMS.metric.id) {
                if (this.height.heightUnitValue !== payload.height) {
                    payload.height = this.height.heightUnitValue;
                }

                if (this.weight.weightUnitValue !== payload.kg) {
                    payload.weight = this.weight.weightUnitValue;
                }
            }

            if (this.user.isDoctor || this.user.isPartnerPlus || this.user.isPartner) {
                delete payload.dob;
                delete payload.gender;
                delete payload.height;
                delete payload.weight;
            }

            if (this.user.isPartner) {
                delete payload.specialty;
            }

            if (payload.specialty) {
                payload.specialty = this.$sanitize(payload.specialty);
            } else if (!this.user.specialty) {
                delete payload.specialty;
            }

            if (payload.profilePictureUrl) {
                payload.profilePictureUrl = payload.profilePictureUrl.includes('https://')
                    ? payload.profilePictureUrl
                    : this.$sanitize(payload.profilePictureUrl);
            }

            try {
                await this.updateProfile(payload);
                this.userProfile = _.cloneDeep(this.user.toProfileJSON());

                if (this.user.dob) {
                    this.userProfile.dob = new Date(moment(this.user.dob).valueOf());
                }

                this.enableEdit = false;

                this.$notify({
                    type: 'success',
                    text: 'You updated your profile successfully!'
                });
            } catch (e) {
                console.error('Error updating profile:', e);
            }
        },
        logout() {
            this.$router.push('/logout');
        },
        async changeNotificationState(e) {
            if (this.mainBrowserNotifState) {
                if (e) {
                    await this.$OneSignal.User.PushSubscription.optIn();
                } else await this.$OneSignal.User.PushSubscription.optOut();

                this.notificationState = !!e;
            } else {
                await this.$OneSignal.Slidedown.promptPush({
                    force: true,
                    forceSlidedownOverNative: true
                });
            }
        }
    }
};
</script>

<style lang="scss">
.col-form-label {
    text-align: left;
    font-weight: 500;
    font-size: 0.875rem !important;
}

.basic-info {
    flex-direction: column;
}

@media (min-width: 576px) {
    .basic-info {
        flex-direction: row;
    }
}
</style>
