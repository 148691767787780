<template>
    <div v-can-access.role="'admin partner-plus'">
        <div class="row justify-content-md-center flex-column mx-0">
            <div class="form-group mt-4">
                <h4 class="text__page-header mt-2">
                    {{ ORDER_MANAGEMENT.headline }}
                </h4>
                <OrdersTable
                    :orders="orderList"
                    @viewUser="viewUserInfo"
                    @viewResult="viewResult"
                    @pageChange="onPageChange"
                    @generateFile="(event) => downloadLabFormOrLabel(event.order, event.resource)"
                    @connectToPartner="
                        (order) => {
                            orderInModal = order;
                            showConnectToPartnerModal = !showConnectToPartnerModal;
                        }
                    "
                    @search="onSearchTerm" />
            </div>
        </div>

        <slot v-if="showConnectToPartnerModal" for="modals">
            <partner-connect-modal
                :show-modal="showConnectToPartnerModal"
                @close="showConnectToPartnerModal = false"
                resource="orderId"
                :resourceId="orderInModal?.id"
                @connectToPartner="onConnectToPartner" />
        </slot>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrdersTable from '@/components/orders-table';
import { ORDER_MANAGEMENT } from '@/utils/constants';
import PartnerConnectModal from '@/components/Modals/partner-connect-modal.vue';
import _ from 'lodash';

export default {
    components: { OrdersTable, PartnerConnectModal },
    data() {
        return {
            ORDER_MANAGEMENT,
            ...this.mapAuthState(),
            showConnectToPartnerModal: false,
            orderInModal: null
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser',
            allOrders: 'order/allOrders',
            status: 'order/status',
            page: 'order/page',
            pageSize: 'order/pageSize',
            outliers: 'order/outliers'
        }),
        orderList() {
            return _.values(this.allOrders);
        }
    },
    async created() {
        await this.getAllOrders();
    },
    methods: {
        ...mapActions({
            getAllOrders: 'order/getAllOrders',
            createPartnerConnection: 'partner/createPartnerConnection',
            generateFormOrLabelForOrders: 'order/generateFormOrLabelForOrders'
        }),
        viewUserInfo(userId) {
            this.$router.push({ name: 'user-info', params: { userId } });
        },
        viewResult(order) {
            this.$router.push({ name: 'result', params: { id: order.orderId }, query: { idType: 'order', userId: order.userId } });
        },
        async onPageChange({ page, status, queryString, outliers }) {
            await this.getAllOrders({
                page,
                pageSize: this.pageSize,
                status,
                queryString,
                outliers
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllOrders({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm,
                status: this.status,
                outliers: this.outliers
            });
        },
        async onConnectToPartner(payload) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                await this.createPartnerConnection(payload);

                this.$notify({
                    type: 'success',
                    text: 'Order connected to partner successfully'
                });

                this.orderInModal = null;
                this.showConnectToPartnerModal = false;
            } catch (error) {
                console.error('Error connecting order:', error);
            }
        },
        async downloadLabFormOrLabel(order, resource = 'lab-form') {
            try {
                const [file] = await this.generateFormOrLabelForOrders({ orderIds: [order.id || order._id], resource });

                console.log(file, 'file');

                const base64Data = file.data || file;

                if (typeof base64Data !== 'string') {
                    console.error('Expected a Base64 string but received:', base64Data);
                    return;
                }

                const byteCharacters = atob(base64Data);
                const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${order.id || order._id}_${resource}.pdf`;
                a.click();

                window.URL.revokeObjectURL(url);
            } catch (err) {
                console.error(err, 'Error downloading PDF');
            }
        }
    }
};
</script>
