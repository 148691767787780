<template>
    <loader :listen="['auth/auth', `user/getUser/${userId}`, 'result/getAllResultsForAUser', 'result/getResult']" class="pt-3 pb-5">
        <div v-if="userId && patientToUse" style="position: relative">
            <div v-can-access.role="'doctor admin partner-plus'" class="text-left pt-4">
                <div class="pb-3 w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
                    <h4 class="text__page-header mt-2 w-100 d-flex justify-content-start align-items-center">
                        <img :src="patientToUse.profilePictureUrl" class="rounded-circle mr-2" width="40" height="40" />
                        User Info
                    </h4>
                </div>
                <div v-if="patientToUse.referrerPartners?.length" class="m-2 font-weight-bold" v-can-access.role="'admin'">
                    Referred by partners with internal code:
                    <span v-for="(partner, index) in patientToUse.referrerPartners" :key="index">
                        <span v-if="index > 0">, </span>
                        <router-link :to="{ name: 'partner-info', params: { partnerCode: partner.code } }">{{ partner.code }}</router-link>
                    </span>
                </div>
                <div class="font-weight-bold m-2" v-can-access.role="'partner-plus'">
                    Referral date:
                    <span class="text-muted">
                        {{ patientToUse.referrerPartners?.find((partner) => partner.code === user.partnerCode)?.dateAdded }}</span
                    >
                </div>
                <patient-basic-info :user-id="patientToUse.id" class="d-flex justify-content-md-center align-items-start w-100 mb-3" />
                <div class="card mb-3">
                    <div class="card-inner border-0 p-4">
                        <h6 class="d-flex justify-content-center align-items-center m-0 justify-content-md-start mb-2">
                            {{ user.useDoctorLanguage ? 'Open or done results (by you)' : "Patient's results" }}
                        </h6>
                        <ResultsTable
                            :results="resultsList"
                            :without-filters="true"
                            :without-patient="true"
                            :show-no-results-image="false"
                            :no-pagination="true" />
                        <h6
                            v-can-access.role="'admin partner-plus'"
                            class="d-flex justify-content-center align-items-center m-0 justify-content-md-start mb-2">
                            User's orders
                        </h6>
                        <OrdersTable
                            v-can-access.role="'admin partner-plus'"
                            :orders="ordersList"
                            :without-filters="true"
                            :without-patient="true"
                            :no-pagination="true"
                            @viewResult="viewResult" />
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </loader>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PatientBasicInfo from '@/components/patient-basic-info.vue';
import { USER_DATA_INFO } from '@/utils/constants';
import ResultsTable from '@/components/results-table.vue';
import OrdersTable from '@/components/orders-table.vue';

export default {
    components: {
        PatientBasicInfo,
        ResultsTable,
        OrdersTable
    },
    data() {
        return {
            USER_DATA_INFO,
            order: null,
            patientToUse: null
        };
    },
    computed: {
        ...mapGetters({
            allUsers: 'user/allUsers',
            user: 'user/currentUser',
            result: 'result/currentResult',
            allUserResults: 'result/allUserResults',
            allUserOrders: 'order/allUserOrders'
        }),
        userId() {
            return this.$route?.params?.userId;
        },
        resultsList() {
            return this.allUserResults[this.patientToUse.id];
        },
        ordersList() {
            return this.allUserOrders[this.patientToUse.id];
        },
        anyResult() {
            const userResults = this.allUserResults[this.patientToUse.id];
            return !!userResults.length;
        }
    },
    async created() {
        const resultId = this.$route.params.resultId || this.$route.query.resultId || this.$route.params.id;

        this.patientToUse = _.find(this.allUsers, (patient) => patient.id === this.userId);

        if (!this.patientToUse) {
            await this.getUser(this.userId);
            this.patientToUse = _.find(this.allUsers, (patient) => patient.id === this.userId);
        }

        const promises = [];

        if (this.user.isAdmin || this.user.isDoctor || this.user.isPartnerPlus) {
            if (resultId && (!this.result || _.isEmpty(this.result) || this.result.id != resultId)) {
                promises.push(this.getResultWithHistoricalData({ resultId: resultId }));
            }

            if (this.patientToUse) {
                promises.push(this.getAllResultsForAUser(this.patientToUse.id));
            }
        }

        if (this.user.isAdmin || this.user.isPartnerPlus) {
            promises.push(this.getAllUserOrders(this.patientToUse.id));
        }

        await Promise.all(promises);
    },
    methods: {
        ...mapActions({
            getResultWithHistoricalData: 'result/getResultWithHistoricalData',
            getUser: 'user/getUser',
            getAllResultsForAUser: 'result/getAllResultsForAUser',
            getAllUserOrders: 'order/getAllUserOrders'
        }),
        viewResult(order) {
            this.$router.push({ name: 'result', params: { id: order.orderId }, query: { idType: 'order', userId: order.userId } });
        }
    }
};
</script>
<style lang="scss">
.card-body {
    width: 100% !important;
}

.doctor-comment {
    display: flex !important;
    align-items: start !important;
}
</style>
