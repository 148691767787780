<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div v-if="loading">Processing your response...</div>
        <div v-else-if="error" class="text-danger">
            <p>Error: {{ errorMessage }}</p>
        </div>
        <div v-else>
            <p>Your response has been processed successfully!</p>
            <button @click="redirectToStore" class="btn btn-primary">Go to Lola Health Store</button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PartnerUserInvitation',
    data() {
        return {
            loading: true,
            error: false,
            errorMessage: ''
        };
    },
    async mounted() {
        const token = this.$route.query.token;
        const response = this.$route.query.response;

        // Check if token and response are present
        if (!token || (response !== 'yes' && response !== 'no')) {
            this.error = true;
            this.errorMessage = 'Invalid invitation link or missing parameters.';
            this.$notify({ type: 'error', text: 'Error: Missing token or response parameters.' });
            this.loading = false;
            return;
        }

        // Prepare the response for API call
        const booleanResponse = response === 'yes';

        try {
            // Call the mapped Vuex action
            const apiResponse = await this.acceptPartnerInvitation({ token, response: booleanResponse });

            if (apiResponse.status === 200) {
                this.loading = false;
            } else {
                throw new Error('Failed to process the invitation.');
            }
        } catch (error) {
            this.error = true;
            this.errorMessage = error.message;
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            acceptPartnerInvitation: 'partner/acceptPartnerInvitation'
        }),
        redirectToStore() {
            // Redirect to the Lola Health Store
            window.location.href = 'https://lolahealth.co';
        }
    }
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>
